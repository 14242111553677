import { NotificationId } from '@src/__generated__/globalTypes';
import { THEME } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';

export const notificationIcon = (type: NotificationId) => {
  switch (type) {
    case NotificationId.BANK_ACCOUNT_CHECK:
    case NotificationId.PAYMENT_COMPLETED:
    case NotificationId.PAYMENT_FAILED:
    case NotificationId.PAYMENT_REQUEST_DONE:
    case NotificationId.AD_HOC_PAYMENT_FLOW_CHANGED:
      return { color: THEME.colors.blue.main, icon: 'credit-card-outlined' };
    case NotificationId.ENGAGEMENT_DRAFT_POST_APPROVED:
    case NotificationId.ENGAGEMENT_DRAFT_POST_REJECTED:
    case NotificationId.MARKETPLACE_DRAFT_POST_APPROVED:
    case NotificationId.MARKETPLACE_DRAFT_POST_REJECTED:
      return { color: THEME.colors.success, icon: 'file-text-outlined' };
    default:
      return { color: THEME.font.colors.black.main, icon: 'briefcase-outlined' };
  }
};

export const notificationRedirectUrl = (campaignId: number, type: NotificationId) => {
  switch (type) {
    case NotificationId.BANK_ACCOUNT_CHECK:
      return generatePath(ROUTES.MY_PAGE_BANK_ACCOUNT);
    case NotificationId.ENGAGEMENT_FINISH:
      return generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID, { id: campaignId });
    case NotificationId.ENGAGEMENT_DRAFT_POST_APPROVED:
    case NotificationId.ENGAGEMENT_DRAFT_POST_REJECTED:
    case NotificationId.ENGAGEMENT_FETCHED:
      return generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST, { id: campaignId });
    case NotificationId.MARKETPLACE_CAMPAIGN_STARTED:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPCOMING:
      return generatePath(ROUTES.FIND_JOBS_MARKETPLACE_ID, { id: campaignId });
    case NotificationId.MARKETPLACE_CAMPAIGN_FINISHED:
    case NotificationId.MARKETPLACE_CAMPAIGN_JOINED:
    case NotificationId.MARKETPLACE_CAMPAIGN_REACH_BUDGET:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPDATE:
    case NotificationId.MARKETPLACE_DRAFT_POST_APPROVED:
    case NotificationId.MARKETPLACE_DRAFT_POST_REJECTED:
    case NotificationId.MARKETPLACE_SHIPPED:
    case NotificationId.MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED:
      return generatePath(ROUTES.MY_JOBS_MARKETPLACE_ID, { id: campaignId });
    case NotificationId.PAYMENT_COMPLETED:
    case NotificationId.PAYMENT_FAILED:
    case NotificationId.PAYMENT_REQUEST_DONE:
      return ROUTES.MY_PAGE_REQUEST_PAYMENTS;
    default:
      return ROUTES.FIND_JOBS;
  }
};
