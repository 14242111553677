import { useMediaQuery } from 'react-responsive';
import { ViewportType } from '../types';

export default () => {
  const isMobileView = useMediaQuery({ maxWidth: ViewportType.TABLET });
  const isSmallView = useMediaQuery({ maxWidth: ViewportType.SMALL });
  const isVerticalView = useMediaQuery({ maxWidth: ViewportType.MEDIUM });

  return {
    isSmallView,
    isMobileView,
    isVerticalView,
  };
};
