import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { css, Global } from '@emotion/react';
import { Icomoon, Icon } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import { CampaignDetailsStyledComponents as SC, DialogAddPosts } from '@src/components/shared';
import { CampaignDetails } from '@src/components/shared/Campaign/Details';
import { DIALOG_REQUIRED_ACTIONS } from '@src/components/shared/Campaign/helpers';
import {
  useAutoManagedJoinedMethodsV2Query,
  useGetAutoManagedCampaignForInfluencerYourJobQuery,
} from '@src/graphql/hooks';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { generatePath, ROUTES } from '@src/shared/routes';
import { AutoManagedAffiliateCampaignStatus } from '@src/__generated__/globalTypes';

const Details = () => {
  const { isMobileView } = usePageLayout();
  const { navigate, params, search } = useQueryHelper();
  const searchParams = new URLSearchParams(search);
  const isPostDialog = !!searchParams.get(DIALOG_REQUIRED_ACTIONS.ADD_POSTS);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(isPostDialog);
  const campaignId = Number(params.id);
  const { data, loading } = useGetAutoManagedCampaignForInfluencerYourJobQuery({
    variables: {
      input: {
        id: campaignId,
      },
    },
  });
  const { data: dataJoinedMethods, loading: loadingJoinedMethods } = useAutoManagedJoinedMethodsV2Query({
    variables: {
      input: {
        campaignId: String(campaignId),
      },
    },
  });
  const autoManagedJoinedMethods = dataJoinedMethods?.autoManagedJoinedMethodsV2?.methods.map(type => ({ type })) || [];

  const campaign = data?.getAutoManagedCampaignForInfluencerYourJob;
  const isFinishedCampaign = [
    AutoManagedAffiliateCampaignStatus.FINISHED,
    AutoManagedAffiliateCampaignStatus.INACTIVE,
  ].includes(campaign?.status as AutoManagedAffiliateCampaignStatus);

  useEffect(() => {
    if (isPostDialog) {
      searchParams.delete(DIALOG_REQUIRED_ACTIONS.ADD_POSTS);
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [isPostDialog]);

  if (loading || loadingJoinedMethods) {
    return (
      <SC.Container>
        <ListLoading />
      </SC.Container>
    );
  }

  if (!campaignId || !campaign) {
    return <Navigate to={ROUTES.MY_JOBS} />;
  }

  const onClickAddPosts = () => {
    setIsDialogOpen(true);
  };

  const onClickReport = () => {
    navigate(generatePath(ROUTES.MY_JOBS_AUTO_MANAGED_ID_REPORT, { id: campaignId }));
  };

  const actionButtons = (
    <>
      <SC.StyledButton
        prefixIcon={<Icomoon icon="file-text-outlined" size={isMobileView ? 16 : 12} />}
        text="Report"
        onClick={onClickReport}
      />
      {!isFinishedCampaign && <SC.StyledButton customPalette={mainBlack} text="Add Posts" onClick={onClickAddPosts} />}
    </>
  );

  return (
    <SC.Container>
      <DialogAddPosts
        campaign={campaign}
        joinedMethods={autoManagedJoinedMethods}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
      {isMobileView ? (
        <SC.BackButton to={ROUTES.MY_JOBS}>
          <Icon>
            <Icomoon css={{ cursor: 'pointer', transform: 'rotate(90deg)' }} icon="arrow-down" size={13} />
          </Icon>
        </SC.BackButton>
      ) : (
        <SC.Header>
          <Link to={ROUTES.MY_JOBS}>
            <Icon>
              <Icomoon css={{ cursor: 'pointer', transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
            </Icon>
          </Link>
          <div>{actionButtons}</div>
        </SC.Header>
      )}

      <CampaignDetails
        campaign={campaign}
        isAutoManaged
        joinedMethods={autoManagedJoinedMethods}
        hasAddPostsButton={!isFinishedCampaign}
        campaignId={campaignId}
      />

      {isMobileView && <SC.ActionContainer>{actionButtons}</SC.ActionContainer>}

      <Global
        styles={
          isMobileView &&
          css({
            '.contact-line-widget': {
              bottom: 96,
            },
          })
        }
      />
    </SC.Container>
  );
};

export default Details;
