import { css } from '@emotion/react';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import anyCreatorBlack from '@src/assets/img/anyCreatorBlack.png';
import { APP_NAME } from '@src/libs/constant';
import { ViewportType } from '@src/libs/types';

interface HeaderProps {
  className?: string;
  href?: LocationDescriptor;
  width?: string;
}

const Logo = (props: HeaderProps) => {
  const { className, href, width } = props;

  return (
    <div className={className} css={{ width: width || '100%' }}>
      {href ? (
        <Link css={styles.anchor} to={href}>
          <img css={{ width: '100%' }} src={anyCreatorBlack} alt={`${APP_NAME} Influencer Marketing Platform`} />
        </Link>
      ) : (
        <img css={{ width: '100%' }} src={anyCreatorBlack} alt={`${APP_NAME} Influencer Marketing Platform`} />
      )}
    </div>
  );
};

const styles = {
  anchor: css({
    cursor: 'pointer',
    transition: 'opacity 0.3s ease-in-out',

    '&:hover': {
      opacity: 0.7,

      [`@media (max-width: ${ViewportType.SMALL}px)`]: {
        opacity: 1,
      },
    },
  }),
};

export default Logo;
