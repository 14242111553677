import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { useCountUnreadNotificationsQuery } from '@src/graphql/hooks';
import { useAuthData } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { UserRoles } from '@src/__generated__/globalTypes';

interface BadgeProps {
  size?: number;
}

const Badge = ({ size = 24 }: BadgeProps) => {
  const { role } = useAuthData();
  const { data } = useCountUnreadNotificationsQuery({
    skip: role === UserRoles.CREATOR_STAFF,
  });
  const count = data?.countUnreadNotifications?.count || 0;

  return (
    <Link css={{ height: size, position: 'relative', width: size }} to={ROUTES.NOTIFICATION}>
      {!!count && <div css={styles.badge}>{count}</div>}
      <Icomoon color="#27313b" css={{ cursor: 'pointer' }} icon="notification-outlined" size={size} />
    </Link>
  );
};

const styles = {
  badge: css({
    backgroundColor: THEME.colors.error,
    borderRadius: 24,
    color: THEME.font.colors.white,
    fontSize: THEME.font.sizes.hint,
    fontWeight: 600,
    padding: '2px 5px',
    position: 'absolute',
    right: -6,
    top: -6,
  }),
};

export default Badge;
