import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import emptyThumbnail from '@src/assets/icon/emptyThumbnail.svg';
import { THEME } from '@src/libs/theme';

type ObjectFit = 'contain' | 'cover' | 'fill';

interface ImageProps {
  alt?: string;
  className?: string;
  fallbackImg?: string;
  hideNoImageText?: boolean;
  height?: string | number;
  loading?: 'eager' | 'lazy';
  objectFit?: ObjectFit;
  src?: string | null;
  width?: string | number;
  onClick?: () => void;
}

const Image = ({
  alt,
  className,
  fallbackImg,
  hideNoImageText,
  height = '100%',
  loading = 'lazy',
  objectFit = 'contain',
  src,
  width = '100%',
  onClick,
}: ImageProps) => {
  const emptyImg = fallbackImg || emptyThumbnail;
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  return isError || !src ? (
    <ImageContainer className={className} height={height} width={width}>
      <StyledImage alt={alt || ''} height="100%" objectFit="fill" src={emptyImg} width="100%" onClick={onClick} />
      {!hideNoImageText && (
        <div css={{ color: THEME.font.colors.white, fontSize: 13, textAlign: 'center' }}>{t('No image')}</div>
      )}
    </ImageContainer>
  ) : (
    <ImageContainer className={className} height={height} width={width}>
      <StyledImage
        alt={alt || ''}
        height={height}
        loading={loading}
        objectFit={objectFit}
        src={src}
        width={width}
        onClick={onClick}
        onError={() => setIsError(true)}
      />
    </ImageContainer>
  );
};

const ImageContainer = styled.div<{ height: string | number; width: string | number }>(({ height, width }) => ({
  background: '#dee5ec',
  display: 'grid',
  height,
  justifyContent: 'center',
  overflow: 'hidden',
  width,
}));

const StyledImage = styled.img<{ height: string | number; objectFit: ObjectFit; width: string | number }>(
  ({ height, objectFit, width }) => ({
    height,
    objectFit,
    width,
  })
);

export default Image;
