import { format, isValid, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import Popover from '@src/components/atoms/Popover';
import { DEFAULT_FNS_DATE_FORMAT } from '@src/libs/constant';
import { getDateLocal } from '@src/libs/date';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { THEME } from '@src/libs/theme';
import TextFormV2 from '../TextForm/TextFormV2';

interface YearMonthPickerProps {
  dateFormat?: string;
  inputFormat?: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const YearMonthPicker = ({
  dateFormat = DEFAULT_FNS_DATE_FORMAT,
  disabled,
  inputFormat = 'yyyy/MM',
  value,
  onChange,
}: YearMonthPickerProps) => {
  const isValidDate = value && isValid(parseISO(value));
  const currentDate = isValidDate ? new Date(value) : new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const [month, setMonth] = useState<number | null>(isValidDate ? currentMonth : null);
  const [year, setYear] = useState<number>(currentYear);
  const { i18n, t } = useTranslation();
  const displayText = value ? format(currentDate.setFullYear(year, month as number), inputFormat) : '';

  useEffect(() => {
    setMonth(isValidDate ? currentMonth : null);
    setYear(currentYear);
  }, [value]);

  const onClose = () => {
    if (month && year) {
      onChange(format(currentDate.setFullYear(year, month as number), dateFormat));
    }
  };

  return (
    <div>
      <Popover
        align="center"
        renderTrigger={
          <div css={{ background: THEME.colors.white }}>
            <TextFormV2
              appendIcon={<Icomoon icon="arrow-down" size={12} />}
              css={styles.textForm}
              disabled={disabled}
              placeholder={t('Select date')}
              prependIcon={<Icomoon icon="calendar" size={16} />}
              value={displayText}
            />
          </div>
        }
        side="bottom"
        onClose={onClose}
      >
        <div css={styles.calendar}>
          <div className="year-selector">
            <Icomoon icon="arrow-down" onClick={() => setYear(currYear => currYear - 1)} />
            <div>{year}</div>
            <Icomoon
              css={year <= currentYear && { pointerEvents: 'none' }}
              icon="arrow-down"
              onClick={() => setYear(currYear => currYear + 1)}
            />
          </div>
          <div className="month-selector">
            {Array(12)
              .fill(0)
              .map((_i, key) => {
                const isActive = month === key;

                return (
                  <div css={isActive && styles.selectedOption} key={key} onClick={() => setMonth(key)}>
                    {format(currentDate.setMonth(key), 'LLL', { locale: getDateLocal(i18n.language as AppLanguage) })}
                  </div>
                );
              })}
          </div>
        </div>
      </Popover>
    </div>
  );
};

const styles = {
  calendar: css({
    borderRadius: THEME.box.borderRadius.m,
    display: 'grid',
    gap: THEME.box.gaps.l,
    height: 220,
    padding: 16,
    width: 311,

    '& > .year-selector': {
      alignItems: 'center',
      display: 'flex',
      height: 32,
      padding: '0 16px',

      '& > svg:nth-of-type(1)': {
        cursor: 'pointer',
        transform: 'rotate(90deg)',

        '[dir="rtl"] &': {
          transform: 'rotate(270deg)',
        },
      },

      '& > div': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        textAlign: 'center',
        width: 'fill-available',
      },

      '& > svg:nth-of-type(2)': {
        cursor: 'pointer',
        transform: 'rotate(270deg)',

        '[dir="rtl"] &': {
          transform: 'rotate(90deg)',
        },
      },
    },

    '& > .month-selector': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',

      '& > div': {
        alignItems: 'center',
        color: THEME.font.colors.black.main,
        cursor: 'pointer',
        display: 'grid',
        fontSize: THEME.font.sizes.subHeading,
        height: 40,
        justifyContent: 'center',
        width: 'fill-available',
      },
    },
  }),
  selectedOption: css({
    background: THEME.colors.black.main,
    color: `${THEME.font.colors.white} !important`,
  }),
  textForm: css({
    '& > div': {
      borderRadius: THEME.box.borderRadius.m,
      height: 38,

      '& > input': {
        fontSize: THEME.font.sizes.subHeading,
        textAlign: 'left',
      },
    },
  }),
};

export default YearMonthPicker;
