import React, { useEffect, useState, ReactNode } from 'react';
import { css } from '@emotion/react';
import * as PopoverPrimitives from '@radix-ui/react-popover';
import { THEME } from '@src/libs/theme';
import { MenuItem } from './MenuItem';

interface PopoverProps {
  align?: 'center' | 'end' | 'start';
  children: ReactNode;
  className?: string;
  renderTrigger: ReactNode;
  side?: 'bottom' | 'left' | 'right' | 'top';
  emitEvents?: (func: { onClose: () => void }) => void;
  onClose?: () => void;
  renderClose?: (func: { onClose: () => void }) => ReactNode;
}

const Popover = ({
  align,
  children,
  className,
  renderTrigger,
  side,
  emitEvents,
  onClose,
  renderClose,
}: PopoverProps) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (emitEvents) {
      emitEvents({ onClose: () => setOpen(false) });
    }
  }, [emitEvents]);

  useEffect(() => {
    if (!open) {
      onClose?.();
    }
  }, [open]);

  return (
    <PopoverPrimitives.Popover open={open} onOpenChange={setOpen}>
      <PopoverPrimitives.Trigger asChild>{renderTrigger}</PopoverPrimitives.Trigger>
      <PopoverPrimitives.Portal>
        <PopoverPrimitives.Content align={align} className={className} css={styles.content} side={side}>
          {children}
          {renderClose?.({ onClose: () => setOpen(false) })}
        </PopoverPrimitives.Content>
      </PopoverPrimitives.Portal>
    </PopoverPrimitives.Popover>
  );
};

const styles = {
  content: css({
    background: THEME.colors.white,
    border: THEME.box.borders.gray.main,
    borderRadius: THEME.box.borderRadius.s,
    boxShadow: THEME.box.shadows.outer,
  }),
};

export { MenuItem, PopoverPrimitives };
export default Popover;
