import React, { useEffect, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icomoon } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import PromotionMethodConnector from '@src/components/organisms/MyPage/SocialConnect/PromotionMethodConnector';
import SocialAccountConnector from '@src/components/organisms/MyPage/SocialConnect/SocialAccountConnector';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useAuthData, useDirLayout, useConnectSocialAuth, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { toolbarBackState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { useAnyXCheckConnectedSocialAccountsQuery, useInfluencerPromotionMethodsQuery } from '@src/graphql/hooks';
import { CampaignPromotionMethod, SocialAccountType, UserRoles } from '@src/__generated__/globalTypes';

const SocialConnect = () => {
  const { role } = useAuthData();
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.CONNECT);
  const { connectSocialAuth: tiktokReconnectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.TIKTOK_RECONNECT);
  const { dir } = useDirLayout();
  const { isMobileView } = usePageLayout();
  const { navigate, t } = useQueryHelper();
  const { recoilState: toolbarState, setRecoilState: setToolbarState } = useRecoil(toolbarBackState);
  const { data, loading } = useAnyXCheckConnectedSocialAccountsQuery();
  const { data: dataPromotionMethods, loading: loadingPromotionMethods } = useInfluencerPromotionMethodsQuery();
  const isEnableDisconnect = [UserRoles.CREATOR_STAFF, UserRoles.INFLUENCER].includes(role as UserRoles);
  const isRtl = dir === 'rtl';

  const backToPreviousPage = () => {
    const { previousPath } = toolbarState;
    if (previousPath) {
      navigate(previousPath);
      setToolbarState({ ...toolbarState, backMethod: null, previousPath: null });
    }
  };

  useEffect(() => {
    if (toolbarState.previousPath) {
      setToolbarState({
        ...toolbarState,
        backMethod: () => {
          backToPreviousPage();
        },
      });
    }
  }, [navigate, toolbarState.previousPath]);

  const onClickConnectAccount = (type: SocialAccountType) => {
    connectSocialAuth(type, { isNewUI: true });
  };

  const onClickNavigate = (e: SyntheticEvent<HTMLAnchorElement>) => {
    if (toolbarState.previousPath) {
      e.preventDefault();
      backToPreviousPage();
    }
  };

  const onClickTiktokReconnect = (socialAccountId: string) => {
    tiktokReconnectSocialAuth(SocialAccountType.TIKTOK, { isNewUI: true, socialAccountId: Number(socialAccountId) });
  };

  const anyXCheckConnectedSocialAccounts = data?.anyXCheckConnectedSocialAccounts;
  const socialAccounts = [
    {
      accounts:
        anyXCheckConnectedSocialAccounts?.instagramAccounts.map(acc => ({
          ...acc,
          type: SocialAccountType.INSTAGRAM,
        })) || [],
      isEnableDisconnect,
      type: SocialAccountType.INSTAGRAM,
      onClickConnect: () => navigate(ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_CONNECT),
      onClickReconnect: () => navigate(ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_CONNECT),
    },
    {
      accounts: [
        ...(anyXCheckConnectedSocialAccounts?.facebookPages.map(acc => ({
          ...acc,
          type: SocialAccountType.FACEBOOK_PAGE,
        })) || []),
        ...(anyXCheckConnectedSocialAccounts?.facebooks.map(acc => ({
          ...acc,
          type: SocialAccountType.FACEBOOK,
        })) || []),
      ],
      isEnableDisconnect,
      type: SocialAccountType.FACEBOOK,
      onClickConnect: () => onClickConnectAccount(SocialAccountType.FACEBOOK),
      onClickReconnect: () => navigate(ROUTES.MY_PAGE_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION),
    },
    {
      accounts:
        anyXCheckConnectedSocialAccounts?.twitterAccounts.map(acc => ({
          ...acc,
          type: SocialAccountType.TWITTER,
        })) || [],
      isEnableDisconnect,
      type: SocialAccountType.TWITTER,
      onClickConnect: () => onClickConnectAccount(SocialAccountType.TWITTER),
    },
    {
      accounts:
        anyXCheckConnectedSocialAccounts?.tiktokAccounts.map(acc => ({
          ...acc,
          type: SocialAccountType.TIKTOK,
        })) || [],
      isEnableDisconnect,
      type: SocialAccountType.TIKTOK,
      onClickConnect: () => onClickConnectAccount(SocialAccountType.TIKTOK),
      onClickReconnect: onClickTiktokReconnect,
    },
    {
      accounts:
        anyXCheckConnectedSocialAccounts?.youtubeAccounts.map(acc => ({
          ...acc,
          type: SocialAccountType.YOUTUBE,
        })) || [],
      isEnableDisconnect,
      type: SocialAccountType.YOUTUBE,
      onClickConnect: () => onClickConnectAccount(SocialAccountType.YOUTUBE),
    },
  ];

  const influencerPromotionMethods = dataPromotionMethods?.influencerPromotionMethods;
  const promotionMethods = [
    {
      icon: 'website',
      isEnableDisconnect,
      methods: influencerPromotionMethods?.websites?.map(item => ({ id: item.id, title: item.url })) || [],
      title: 'Website',
      type: CampaignPromotionMethod.WEBSITE,
    },
    {
      icon: 'mail-outlined',
      isEnableDisconnect,
      methods: influencerPromotionMethods?.emailNewsLetters?.map(item => ({ id: item.id, title: item.email })) || [],
      title: 'Email / Newsletter',
      type: CampaignPromotionMethod.EMAIL_NEWSLETTER,
    },
    {
      icon: 'smartphone',
      isEnableDisconnect,
      methods: influencerPromotionMethods?.mobileApps?.map(item => ({ id: item.id, title: item.url })) || [],
      title: 'Mobile App',
      type: CampaignPromotionMethod.MOBILE_APP,
    },
    {
      icon: 'podcast',
      isEnableDisconnect,
      methods: influencerPromotionMethods?.podCasts?.map(item => ({ id: item.id, title: item.url })) || [],
      title: 'Podcast',
      type: CampaignPromotionMethod.PODCAST,
    },
    {
      icon: 'ellipsis',
      isEnableDisconnect,
      methods: influencerPromotionMethods?.offlines?.map(item => ({ id: item.id, title: item.description })) || [],
      title: 'Offline / Others',
      type: CampaignPromotionMethod.OFFLINE,
    },
  ];

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.backNavigator}>
          <Link to={ROUTES.MY_PAGE} onClick={onClickNavigate}>
            <Icomoon css={{ transform: isRtl ? 'rotate(270deg)' : 'rotate(90deg)' }} icon="arrow-down" size={10} />
          </Link>
          <label>{t('Title.Social Connect')}</label>
        </div>
      )}
      <div css={styles.contentContainer}>
        <div css={styles.description}>
          {t('Annotation.Connect with your social account to use Analytics data and get sponsorship campaign')}
        </div>

        {loading || loadingPromotionMethods ? (
          <ListLoading css={{ borderRadius: 15 }} />
        ) : (
          <>
            {socialAccounts.map(items => (
              <SocialAccountConnector key={items.type} {...items} />
            ))}

            {promotionMethods.map(items => (
              <PromotionMethodConnector key={items.type} {...items} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  backNavigator: css({
    alignItems: 'center',
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    display: 'flex',
    gap: THEME.box.gaps.m,

    '& > a': {
      alignItems: 'center',
      background: THEME.colors.white,
      borderRadius: THEME.box.borderRadius.m,
      boxShadow: THEME.box.shadows.outer,
      display: 'grid',
      height: 40,
      justifyContent: 'center',
      width: 40,
    },
  }),
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '24px 16px',
    },
  }),
  contentContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.l,
    padding: '24px 0',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: '0 16px',
    },
  }),
};

export default SocialConnect;
