import { atom } from 'recoil';

export interface LayoutStateProps {
  hasAnalyticsTabs: boolean;
  hasContactForm: boolean;
  hasIntercomWidget: boolean;
  hasLineWidget: boolean;
  hasMobileMenu: boolean;
  isMenuOpen: boolean;
  isUuum: boolean;
}

export const layoutState = atom<LayoutStateProps>({
  key: 'layoutState',
  default: {
    hasAnalyticsTabs: false,
    hasContactForm: false,
    hasIntercomWidget: false,
    hasLineWidget: false,
    hasMobileMenu: false,
    isMenuOpen: false,
    isUuum: window.location !== window.parent.location,
  },
});
