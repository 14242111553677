import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useGlobalLayout, useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ENABLED_INTERCOM_COUNTRIES } from '@src/libs/constant';
import { ROUTES } from '@src/shared/routes';
import { useInfluencerUpdateDetailV2Query } from '@src/graphql/hooks';

const ALLOWED_INTERCOM_PAGES = ['/payment', '/settings'];

interface InfluencerInfo {
  email: string;
  name: string;
  userId: string;
  country: {
    id: string;
    name: string;
  };
}

const initInfluencerInfo = {
  email: '',
  name: '',
  userId: '',
  country: {
    id: '',
    name: '',
  },
};
const IntercomInitializer = () => {
  const { userId } = useAuthData();

  if (!userId) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  const { isMenuOpen, isUuum, setGlobalLayout } = useGlobalLayout();
  const { boot, update } = useIntercom();
  const {
    pathname,
    i18n: { language },
  } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  const [influencerInfo, setInfluencerInfo] = useState<InfluencerInfo>(initInfluencerInfo);
  const isEnabledCountry = ENABLED_INTERCOM_COUNTRIES.includes(influencerInfo.country.id);
  const hasIntercomWidget =
    !!influencerInfo.userId &&
    !isMenuOpen &&
    !isUuum &&
    isEnabledCountry &&
    (ALLOWED_INTERCOM_PAGES.includes(pathname) || !isMobileView);

  React.useEffect(() => {
    if (influencerInfo.userId) {
      update({
        hideDefaultLauncher: !hasIntercomWidget,
      });
    }
  }, [pathname, isMobileView, isMenuOpen, isUuum]);

  useEffect(() => {
    setGlobalLayout({
      hasContactForm: influencerInfo.country.id ? !isEnabledCountry : false,
      hasIntercomWidget,
    });
  }, [influencerInfo.country.id, hasIntercomWidget]);

  useInfluencerUpdateDetailV2Query({
    variables: { pk: userId },
    onCompleted: data => {
      const influencerUpdateDetailV2 = data.influencerUpdateDetailV2;

      const userInfo = {
        userId: influencerUpdateDetailV2?.id.toString() || '',
        email: influencerUpdateDetailV2?.email || '',
        name: influencerUpdateDetailV2?.name || '',
        country: {
          id: influencerUpdateDetailV2?.country?.id || '',
          name: influencerUpdateDetailV2?.country?.name || '',
        },
      };
      setInfluencerInfo(userInfo);
      const isFromAllowedCountries = influencerUpdateDetailV2?.country.id
        ? ENABLED_INTERCOM_COUNTRIES.includes(influencerUpdateDetailV2.country.id)
        : false;
      const isOnAllowedPage = ALLOWED_INTERCOM_PAGES.includes(pathname) || !isMobileView;

      boot({
        ...userInfo,
        languageOverride: language || 'en',
        hideDefaultLauncher: !isFromAllowedCountries || !isOnAllowedPage || isUuum,
      });
    },
  });

  return <></>;
};

export default IntercomInitializer;
