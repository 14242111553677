import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { ErrorMessage } from '@src/components/atoms';
import { BackNavigatorV2, ThemeButton } from '@src/components/molecules';
import { SentenceFormV2 } from '@src/components/shared';
import { useSendContactMutation } from '@src/graphql/hooks';
import { useHelpCenterUrl } from '@src/libs/help';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { lineWidgetState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { ViewportType } from '@src/libs/types';

interface FormValues {
  enquiry: string;
}

const Contact = () => {
  const { helpCenterUrl } = useHelpCenterUrl();
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, t } = useQueryHelper();
  const { setRecoilState } = useRecoil(lineWidgetState);
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    watch,
  } = useForm<FormValues>({
    defaultValues: { enquiry: '' },
    resolver: yupResolver(
      yup.object().shape({
        enquiry: yup.string().required('requiredMessage'),
      })
    ),
  });
  const [enquiry] = watch(['enquiry']);
  const [sendContact] = useSendContactMutation();

  useEffect(() => {
    setRecoilState({ extendedBottom: isMobileView ? 64 : 0 });

    return () => {
      setRecoilState({ extendedBottom: 0 });
    };
  }, [isMobileView]);

  const onSubmit = async (values: FormValues) => {
    try {
      const result = await sendContact({
        variables: {
          input: values,
        },
      });
      if (result.data?.sendContact?.ok) {
        enqueueSnackbar(t('succeededInSending'), { variant: 'success' });
        sendAmplitudeEvent(eventTypes.sendContact);
      } else {
        enqueueSnackbar(t('failedToSend'), { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  const sendButton = (
    <ThemeButton
      css={styles.sendBtn}
      customPalette={mainBlack}
      disabled={!enquiry}
      loading={isSubmitting}
      text="Send"
      type="submit"
    />
  );

  return (
    <form css={{ padding: '24px 16px', position: 'relative' }} onSubmit={handleSubmit(onSubmit)}>
      {!isMobileView && (
        <BackNavigatorV2
          actionItem={<div css={{ display: 'grid', justifyContent: 'flex-end' }}>{sendButton}</div>}
          backPath={ROUTES.MY_PAGE}
          css={{ marginBottom: 24 }}
          title="Contact"
        />
      )}

      <div css={styles.gotAQuestion}>
        <Trans
          components={{ 1: <Link rel="noopener noreferrer" target="_blank" to={helpCenterUrl} /> }}
          i18nKey="Help.ContactGotAQuestion"
        />
      </div>

      <div css={styles.form}>
        <SentenceFormV2
          isRequired
          placeholder="Enter the details of your inquiry"
          title="Message"
          {...register('enquiry')}
        />
        <ErrorMessage message={errors.enquiry?.message} />
      </div>

      {isMobileView && <div css={styles.actionContainer}>{sendButton}</div>}
    </form>
  );
};

const styles = {
  actionContainer: css({
    background: THEME.colors.white,
    bottom: 0,
    left: 0,
    padding: 16,
    position: 'fixed',
    width: 'fill-available',
  }),
  form: css({
    background: THEME.colors.white,
    borderRadius: 15,
    filter: 'drop-shadow(0px 0px 1px rgba(110, 124, 137, 0.20)) drop-shadow(0px 0px 24px rgba(110, 124, 137, 0.05))',
    padding: 16,
  }),
  gotAQuestion: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,

    '& > a': {
      color: THEME.font.colors.blue.main,
      display: 'inline-block',
    },
  }),
  sendBtn: css({
    borderRadius: 9,
    height: 56,
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      width: 226,
    },

    '& > span': {
      fontSize: THEME.font.sizes.subHeading,
    },
  }),
};

export default Contact;
