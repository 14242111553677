import amplitude from 'amplitude-js';

export const eventTypes = {
  // public
  viewSignUp: 'view sign up',
  viewSignUpEmail: 'view sign up email',
  viewSignUpIstagram: 'view sign up instagram',
  viewSignUpInstagramUnableConnect: 'view sign up instagram unable connect',
  viewSignUpTiktokGrantPermission: 'view sign up tiktok grant permission',
  viewSignUpFacebook: 'view sign up facebook',
  viewSignUpFacebookGrantPermission: 'view sign up facebook grant permission',
  viewSignUpSuccess: 'view sign up success',
  viewSignIn: 'view sign in',
  viewSignInInstagramUnableConnect: 'view sign in instagram unable connect',
  viewSignInTiktokGrantPermission: 'view sign in tiktok grant permission',
  viewSignInFacebookGrantPermission: 'view sign in facebook grant permission',
  viewSignInFailedConnect: 'view sign in failed connect',
  viewSignInEmail: 'view sign in email',
  viewPasswordForgot: 'view password forgot',
  viewPasswordRegister: 'view password register',
  viewPasswordReset: 'view password reset',
  signUp: 'sign up',
  signIn: 'sign in',
  signOut: 'sign out',
  // youtube cms dashboard
  viewYoutubeCmsDashboard: 'view youtube cms dashboard',
  // find jobs
  viewFindJobs: 'view find jobs',
  viewFindJobDetails: 'view find job details',
  // my jobs
  viewMyJobs: 'view my jobs',
  viewMyJobDetails: 'view my job details',
  viewMyJobReport: 'view my job report',
  viewMyJobSubmitDraftPost: 'view my job submit draft post',
  // my page
  viewMyPage: 'view my page',
  viewMyPageRequestPayments: 'view my page request payments',
  viewMyPagePaymentHistory: 'view my page payment history',
  viewMyPageBankAccount: 'view my page bank account',
  editBankAccount: 'edit bank account',
  viewMyPageAccount: 'view my page account',
  editMyPageAccount: 'edit my page account',
  viewMyPageAccountDelete: 'view my page account delete',
  viewMyPageAddress: 'view my page address',
  editMyPageAddress: 'edit my page address',
  viewMyPageUsers: 'view my page users',
  addUser: 'add user',
  viewMyPageSnsBudget: `view my page social account's budget`,
  editMyPageSnsBudget: `edit my page social account's budget`,
  viewMyPageChannelConnect: 'view my page channel connect',
  viewMyPageChannelConnectAddLine: 'view my page channel connect add line',
  addLine: 'add line',
  viewMyPageChannelConnectAddShopify: 'view my page channel connect add shopify',
  addShopify: 'add shopify',
  viewMyPageSocialConnect: 'view my page social connect',
  viewMyPageSocialConnectInstagram: 'view my page social connect instagram',
  viewMyPageSocialConnectFacebookGrantPermission: 'view my page social connect facebook grant permission',
  viewMyPageSocialConnectTiktokGrantPermission: 'view my page social connect tiktok grant permission',
  viewMyPageSocialConnectAddMethods: 'view my page social connect add methods',
  addPromotionMethods: 'add promotion methods',
  // home
  viewHome: 'view home portal',
  // link in bio
  viewLinkInBio: 'view link in bio',
  viewLinkInBioUrl: 'view link in bio url',
  viewLinkInBioProfile: 'view link in bio profile',
  viewLinkInBioLinks: 'view link in bio links',
  viewLinkInBioShare: 'view link in bio share',
  editLinkInBio: 'edit link in bio',
  // analytics
  viewAnalyticsLinkInBio: 'view analytics link in bio',
  viewAnalyticsInstagram: 'view analytics instagram',
  viewAnalyticsInstagramPostFeed: 'view analytics instagram post feed',
  viewAnalyticsInstagramStoryFeed: 'view analytics instagram story feed',
  viewAnalyticsInstagramReelPosts: 'view analytics instagram reel posts',
  viewAnalyticsInstagramInteractionUserAnalysis: 'view analytics instagram interaction user analysis',
  viewAnalyticsInstagramInteractionTaggedPosts: 'view analytics instagram interaction tagged posts',
  viewAnalyticsInstagramInteractionMentionedPosts: 'view analytics instagram interaction mentioned posts',
  viewAnalyticsFacebook: 'view analytics facebook',
  viewAnalyticsFacebookPostFeed: 'view analytics facebook post feed',
  viewAnalyticsTwitter: 'view analytics twitter',
  viewAnalyticsYoutube: 'view analytics youtube',
  viewAnalyticsYoutubeVideosFeed: 'view analytics youtube videos feed',
  viewAnalyticsYoutubeTagRankingList: 'view analytics youtube tag ranking list',
  viewAnalyticsYoutubeCompareVideosFeed: 'view analytics youtube compare videos feed',
  viewAnalyticsTiktok: 'view analytics tiktok',
  // marketplace
  viewMarketplaceSearchJob: 'view marketplace search job',
  viewMarketplaceSearchJobDetails: 'view marketplace search job details',
  viewMarketplaceYourJob: 'view marketplace your job',
  viewMarketplaceYourJobDetails: 'view marketplace your job details',
  viewMarketplaceYourJobDetailsReport: 'view marketplace your job details report',
  viewMarketplaceYourJobAffiliateReport: 'view marketplace your job affiliate report',
  joinMarketplace: 'join marketplace',
  // engagement
  viewEngagementYourJob: 'view engagement your job',
  viewEngagementYourJobDetails: 'view engagement your job details',
  viewEngagementYourJobDetailsPosts: 'view engagement your job details posts',
  viewEngagementYourJobDetailsPostAdd: 'view engagement your job details post add',
  viewEngagementYourJobDetailsPostDetails: 'view engagement your job details post details',
  viewEngagementYourJobDetailsReport: 'view engagement your job details report',
  addEngagementPost: 'add engagement post',
  deleteEngagementPost: 'delete engagement post',
  editEngagementPost: 'edit engagement post',
  // fans
  viewFans: 'view fans',
  viewFanDetails: 'view fan details',
  viewFanActivities: 'view fan activities',
  viewFanOrders: 'view fan orders',
  viewFanForms: 'views fan forms',
  viewFanFormDetails: 'view fan form details',
  addFan: 'add fan',
  addFanActivityComment: 'add fan activity comment',
  deleteFanActivityComment: 'delete fan activity comment',
  editFan: 'edit fan',
  editFanActivityComment: 'edit fan activity comment',
  // emails
  viewEmails: 'view emails',
  viewEmailDetails: 'view email details',
  viewEmailReport: 'view email report',
  composeEmail: 'compose email',
  editEmail: 'edit email',
  // chats
  viewChat: 'view chat',
  // broadcast
  viewBroadcast: 'view broadcast',
  viewBroadcastDetails: 'view broadcast details',
  addBroadcast: 'add broadcast',
  editBroadcast: 'edit broadcast',
  sendBroadcast: 'send broadcast',
  // forms
  viewForms: 'view forms',
  viewFormDetails: 'view form details',
  viewFormLivePreview: 'view live preview form',
  viewFormSubmit: 'view submit form',
  addForm: 'add form',
  editForm: 'edit form',
  // notification
  viewNotification: 'view notification',
  // settings
  viewSettingsAccount: 'view settings account',
  editSettingsAccount: 'edit settings account',
  viewSettingsAccountDelete: 'view settings account delete',
  deleteAccount: 'delete account',
  viewSettingsAddress: 'view settings address',
  editSettingsAddress: 'edit settings address',
  viewSettingsSocialConnect: 'view settings social connect',
  viewSettingsSocialConnectInstagramConnect: 'view settings social connect instagram connect',
  viewSettingsSocialConnectInstagramUnableConnect: 'view settings social connect instagram unable connect',
  viewSettingsSocialConnectTiktokGrantPermission: 'view settings social connect tiktok grant permission',
  viewSettingsSocialConnectFacebookGrantPermission: 'view settings social connect facebook grant permission',
  connectSocial: 'connect social',
  connectShopify: 'connect shopify',
  signInInstagramLine: 'sign in instagram line',
  viewSettingsPayment: 'view settings payment',
  editSettingsPayment: 'edit settings payment',
  viewSettingsUsers: 'view settings users',
  viewSettingsUserDetails: 'view settings user details',
  viewSettingsSnsBudget: 'view settings sns budget',
  editSettingsSnsBudget: 'edit settings sns budget',
  // payment
  viewPayment: 'view payment',
  viewPaymentHistory: 'view payment history',
  requestPayment: 'request payment',
  // terms and conditions
  viewContact: 'view contact',
  viewTerms: 'view terms',
  viewPrivacy: 'view privacy',
  sendContact: 'send contact',
};

export const initAmplitude = (id: string) => {
  amplitude.getInstance().init(id);
};

export const setAmplitudeUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId);
};

export const sendAmplitudeEvent = (eventType: string, eventProperties?: any) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
