import { format } from 'date-fns';
import React, { useEffect, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { sendAmplitudeEvent } from '@src/amplitude';
import { InitialLoading } from '@src/components/atoms';
import { CrowdinWidget } from '@src/components/molecules';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import {
  useYoutubeCmsConfirmedRevenueForInfluencerQuery,
  useYoutubeCmsRevenueForInfluencerQuery,
} from '@src/graphql/hooks';
import { checkSignedIn, removeToken, setToken } from '@src/libs/auth';
import { DEFAULT_FNS_DATE_FORMAT } from '@src/libs/constant';
import { useDeepCompareEffect, useQueryHelper, useUuumInfluencerView } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';

interface PublicRouteProps {
  amplitudeEvent?: string;
  element: ReactElement;
}

const PublicRoute = ({ amplitudeEvent, element }: PublicRouteProps) => {
  const isSignedIn = checkSignedIn();
  const { setUp } = useAuthSetup();
  const { enqueueSnackbar, navigate, pathname, state, t } = useQueryHelper();
  const { isUuumView } = useUuumInfluencerView();
  const date = new Date();
  const firstDayOfTheMonth = format(new Date(date.getFullYear(), date.getMonth(), 1), DEFAULT_FNS_DATE_FORMAT);

  const { data, loading } = useYoutubeCmsConfirmedRevenueForInfluencerQuery({
    skip: !isSignedIn,
    variables: {
      month: firstDayOfTheMonth,
    },
  });
  const { data: dataRevenue, loading: loadingRevenue } = useYoutubeCmsRevenueForInfluencerQuery({
    skip: !isSignedIn,
    variables: {
      month: firstDayOfTheMonth,
    },
  });

  // chrome version 113 and above with new feature storage partition
  // this cause popup window cannot share localStorage with parent iframe

  // UUUM application will need popup window for youtube authentication
  // so pass token into state to reset into localStorage
  useDeepCompareEffect(() => {
    const refreshToken = state?.refreshToken;
    const token = state?.token;

    const setAuthentication = async () => {
      try {
        setToken(token, refreshToken);
        await setUp(token);
        navigate(pathname, { state: null });
      } catch (err) {
        removeToken();
        enqueueSnackbar(t(err.message), { variant: 'error' });
      }
    };

    if (isUuumView && (refreshToken || token)) {
      setAuthentication();
    }
  }, [isUuumView, state]);

  useEffect(() => {
    if (amplitudeEvent) {
      sendAmplitudeEvent(amplitudeEvent);
    }
  }, [amplitudeEvent]);

  if (loading || loadingRevenue) {
    return <InitialLoading />;
  }

  if (isSignedIn) {
    return (
      <Navigate
        to={
          isUuumView
            ? generatePath(ROUTES.ANALYTICS, undefined, { sm: SocialAccountType.YOUTUBE })
            : data?.youtubeCmsConfirmedRevenueForInfluencer?.revenue ||
              dataRevenue?.youtubeCmsRevenueForInfluencer?.revenue
            ? ROUTES.YOUTUBE_CMS_DASHBOARD
            : ROUTES.FIND_JOBS
        }
      />
    );
  }

  return (
    <>
      {element}
      <CrowdinWidget />
    </>
  );
};

export default PublicRoute;
