import React from 'react';
import { Trans } from 'react-i18next';
import ReactSlick, { Settings } from 'react-slick';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, Icomoon, SliderDots, TextCutter } from '@src/components/atoms';
import { CarouselV2, ThemeButton } from '@src/components/molecules';
import CampaignBudgetProgressBar from '@src/components/organisms/CastingMarketplace/YourJob/CampaignBudgetProgressBar';
import { RichEditor } from '@src/components/shared';
import { CURRENCY_SYMBOLS, JAPANESE_LANG } from '@src/libs/constant';
import { localizedDateFormatter } from '@src/libs/date';
import { formatNumberWithCommas, formatPercent } from '@src/libs/format';
import { useCopy, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { mainBlack } from '@src/libs/palette';
import { switchImage, switchSocialBackgroundColor, switchText } from '@src/libs/socialMedia';
import { ViewportType } from '@src/libs/types';
import { toolbarBackState, useRecoil } from '@src/recoilAtoms';
import { generatePath, ROUTES } from '@src/shared/routes';
import {
  AnyXMarketplaceCampaignStatus,
  CampaignPromotionMethod,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  MarketplaceCampaignAppliedStatus,
  MarketplaceCampaignDetailType,
  SocialAccountType,
  UserRoles,
} from '@src/__generated__/globalTypes';
import { useMadeMarketplaceCampaignPostMutation } from '@src/graphql/hooks/useMadeMarketplaceCampaignPost';
import { THEME } from '@src/libs/theme';
import SelectionMessage from '../SelectionMessage';
import { MOBILE_MAX_WIDTH, RICH_EDITOR_STYLES, SELECTION_ACTIONS, getPromotionMethods } from '../helpers';
import { CampaignDetailsProps } from '../types';
import { isDateWithinTheRange, isNumber } from '../../helpers';
import CaptionGenerator from './CaptionGenerator';

const CampaignDetails = ({
  campaign,
  isAutoManaged,
  isJoinableMethods = true,
  joinedMethodAccounts,
  joinedMethods,
  previewOnlyData,
  rewardPlaceholder = '-',
  searchId,
  campaignId,
  hasAddPostsButton,
}: CampaignDetailsProps) => {
  const {
    anyXStatus,
    appliedStatus,
    campaignDetailType,
    campaignUrl,
    clickUrl,
    commissionRate,
    confirmMadePostAvailability,
    couponCode,
    createdDate,
    currency,
    endDate,
    hashtags,
    id,
    isApplied,
    isAutoInfluencerApproval,
    isCouponCampaign,
    isJoined,
    isPostTracking,
    isSelectionCampaign,
    materials,
    materialUrl,
    maximumRevenuePerInfluencer,
    minFollowers,
    preLaunchDate,
    remainingAmount,
    requirement,
    revenuePerAction,
    revenuePerClick,
    revenuePerComment,
    revenuePerFollower,
    revenuePerLike,
    revenuePerOrder,
    revenuePerPost,
    revenuePerShare,
    revenuePerView,
    sampleUrl,
    serviceInformation,
    serviceUrl,
    socialMedias = [CampaignSocialMediaType.UNSELECT],
    startDate,
    status,
    title,
    thumbNail,
    thumbnails,
    totalAmount,
    trackingUrl,
  } = campaign;
  const { isMobileView } = usePageLayout();
  const { recoilState, setRecoilState } = useRecoil(toolbarBackState);
  const { navigate, i18n, t, enqueueSnackbar } = useQueryHelper();
  const { handleCopyHasHashtag, handleCopyHasText } = useCopy();
  const [madeMarketplaceCampaignPost, { loading }] = useMadeMarketplaceCampaignPostMutation({
    refetchQueries: ['MarketplaceForInfluencer'],
    onCompleted: results => {
      if (results.madeMarketplaceCampaignPost?.ok) {
        enqueueSnackbar(t('Reported posted tweet successfully'), { variant: 'success' });
      }
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const handleMadePostClick = () => {
    if (campaignId) {
      madeMarketplaceCampaignPost({
        variables: {
          input: { campaignId },
        },
      });
    }
  };
  const onClickRedirectSocialConnect = () => {
    if (searchId) {
      // TODO: need redirect to my page social connect after migrate to new UI
      setRecoilState({
        ...recoilState,
        previousPath: generatePath(ROUTES.FIND_JOBS_MARKETPLACE_ID, { id: searchId }),
      });
    }
    navigate(ROUTES.MY_PAGE_SOCIAL_CONNECT);
  };

  const campaignThumbnails = !!thumbnails?.length ? thumbnails : [thumbNail];
  const campaignPromotionMethod = socialMedias.find(socialMedia => socialMedia === CampaignSocialMediaType.OTHER);
  const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] : '';
  const isCampaignSocialMedias = socialMedias.find(
    socialMedia => ![CampaignSocialMediaType.OTHER, CampaignSocialMediaType.UNSELECT].includes(socialMedia)
  );
  const isCampaignStarted = new Date(startDate) <= new Date();
  const isEcommerceCampaign = campaignDetailType === MarketplaceCampaignDetailType.E_COMMERCE;
  const isJPLanguage = i18n.language === JAPANESE_LANG;
  const isJoinedCampaign = isApplied || isJoined;
  const isManualApprovalApplying = !!(
    isSelectionCampaign &&
    !isAutoInfluencerApproval &&
    [MarketplaceCampaignAppliedStatus.APPLYING, null].includes(appliedStatus as MarketplaceCampaignAppliedStatus | null)
  );
  const isNewCampaign = createdDate && isDateWithinTheRange(createdDate, 5);
  const isOverBudget = !isAutoManaged && status === CampaignStatusForInfluencer.SUSPENDED;
  const isPosting = anyXStatus === AnyXMarketplaceCampaignStatus.POSTING;
  const isPreLaunchSelection = preLaunchDate && new Date(startDate) > new Date();
  const hidePostRequirements = isSelectionCampaign && !appliedStatus && !isJoinableMethods;
  // searchId is referring to find job ID & marketplace campaign will pass socialMedias prop
  const isCaptionGenerator =
    !searchId &&
    !isManualApprovalApplying &&
    campaign?.socialMedias?.find(s =>
      [
        CampaignSocialMediaType.FACEBOOK,
        CampaignSocialMediaType.INSTAGRAM,
        CampaignSocialMediaType.INSTAGRAM_STORY,
        CampaignSocialMediaType.TIKTOK,
        CampaignSocialMediaType.TWITTER,
        CampaignSocialMediaType.YOUTUBE,
      ].includes(s)
    );

  const promotionMethods = getPromotionMethods(campaignPromotionMethod);
  const promotionMethodValues = promotionMethods.map(({ value }) => value);
  const revenues = [
    ...(isNumber(revenuePerAction) ? [{ title: 'RevenuePer.Action', value: revenuePerAction }] : []),
    ...(isNumber(revenuePerClick) ? [{ title: 'RevenuePer.Click', value: revenuePerClick }] : []),
    ...(isNumber(revenuePerComment) ? [{ title: 'RevenuePer.Comment', value: revenuePerComment }] : []),
    ...(isNumber(revenuePerFollower) ? [{ title: 'RevenuePer.Follower', value: revenuePerFollower }] : []),
    ...(isNumber(revenuePerLike) ? [{ title: 'RevenuePer.Like', value: revenuePerLike }] : []),
    ...(isNumber(revenuePerOrder) ? [{ title: 'RevenuePer.Order', value: revenuePerOrder }] : []),
    ...(isNumber(revenuePerPost) ? [{ title: 'RevenuePer.Post', value: revenuePerPost }] : []),
    ...(isNumber(revenuePerShare) ? [{ title: 'RevenuePer.Share', value: revenuePerShare }] : []),
    ...(isNumber(revenuePerView) ? [{ title: 'RevenuePer.View', value: revenuePerView }] : []),
    ...(isNumber(commissionRate) ? [{ isPercentage: true, title: 'Commission rate', value: commissionRate }] : []),
  ];

  const showBudgetProgressBar =
    !(
      [MarketplaceCampaignDetailType.REFERRAL_CODE, MarketplaceCampaignDetailType.URL_TRACKING].includes(
        campaignDetailType as MarketplaceCampaignDetailType
      ) && !endDate
    ) &&
    !(isSelectionCampaign && !isCampaignStarted) &&
    campaignDetailType !== MarketplaceCampaignDetailType.E_COMMERCE &&
    remainingAmount !== undefined &&
    totalAmount !== undefined &&
    !!revenues.length;

  const carouselDimensions = {
    height: isMobileView ? 230 : 432,
    width: isMobileView ? (previewOnlyData?.iframeWidth ? previewOnlyData?.iframeWidth - 16 * 2 : 343) : 648,
  };
  const slickSettings: Settings = {
    // hit eslint missing display name when using arrow function
    appendDots: function appendDots(dots) {
      return <SliderDots dots={dots} dotWidth={12} numDotsToShow={8} />;
    },
    dots: true,
  };

  return (
    <div css={[styles.container, previewOnlyData?.styles ? css(previewOnlyData.styles) : undefined]}>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <ReactSlick className="thumbnail-container" css={styles.imageContainer} {...slickSettings}>
          {campaignThumbnails.map((thumbnail, index) => (
            <img key={index} src={thumbnail || ''} />
          ))}
        </ReactSlick>
      </div>

      <div css={styles.contentContainer} className="content-container">
        <div css={{ display: 'flex', gap: 10 }}>
          {isOverBudget ? (
            <Tag color="#d4d8dc">
              <div>{t('Closed')}</div>
            </Tag>
          ) : isSelectionCampaign ? (
            isPreLaunchSelection && isAutoInfluencerApproval ? (
              <Tag color="#ffb63d">
                {isEcommerceCampaign && <Icomoon color="#fff" icon="crown" size={10} />}
                <div>{t('Coming soon')}</div>
              </Tag>
            ) : (
              <Tag color="#7ec963">
                {isEcommerceCampaign && <Icomoon color="#fff" icon="crown" size={10} />}
                <div>{t('Selection')}</div>
              </Tag>
            )
          ) : isEcommerceCampaign ? (
            <Tag color="#43Bde3">
              <Icomoon color="#fff" icon="crown" size={10} />
              <div>{t('Special Deal')}</div>
            </Tag>
          ) : isNewCampaign ? (
            <Tag color="#e3436a">{t('New')}</Tag>
          ) : null}

          <div css={styles.socialMediasContainer} className="social-medias-container">
            {socialMedias
              .filter(socialMedia => socialMedia !== CampaignSocialMediaType.OTHER)
              .map(socialMedia =>
                socialMedia === CampaignSocialMediaType.UNSELECT ? (
                  <div css={styles.anyMethodsIndicator} key={socialMedia}>
                    {t('Any Methods')}
                  </div>
                ) : (
                  <div css={styles.socialMediaIndicator} key={socialMedia}>
                    <Icomoon icon={switchImage({ socialMedia })} />
                  </div>
                )
              )}
          </div>
        </div>

        <div css={{ marginBottom: 22 }}>
          <div css={styles.title} className="campaign-title">
            {title}
          </div>

          {startDate && (
            <div css={styles.campaignPeriodContainer} className="campaign-period-container">
              <Icomoon icon="calendar" size={16} />
              <div className="start-date">
                {localizedDateFormatter(
                  new Date(startDate),
                  isJPLanguage ? 'MMMdo' : 'LLLL dd',
                  i18n.language as AppLanguage
                )}
              </div>
              {endDate && (
                <>
                  <span>-</span>
                  <div className="end-date">
                    {localizedDateFormatter(
                      new Date(endDate),
                      isJPLanguage ? 'MMMdo' : 'LLLL dd',
                      i18n.language as AppLanguage
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        {!!revenues.length && (
          <div css={styles.rewardContainer} className="reward-container">
            <div>{t('Label.Reward')}</div>
            {previewOnlyData?.userRole === UserRoles.ADVERTISER ? (
              <div css={{ fontSize: '14px', fontWeight: 600, width: '100%', textAlign: 'end' }}>
                {t('Reward amount')}
              </div>
            ) : (
              <div>
                {!!revenues.length ? (
                  revenues.map(({ isPercentage, title: revenueTitle, value }) => (
                    <div key={revenueTitle}>
                      <div>
                        {isPercentage
                          ? formatPercent(value)
                          : `${currencySymbol}${formatNumberWithCommas(value, undefined, currency || undefined)}`}
                      </div>
                      <div>{`/${t(revenueTitle)}`}</div>
                    </div>
                  ))
                ) : (
                  <div
                    css={{ color: THEME.font.colors.black.main, fontSize: 28, fontWeight: 600 }}
                    className="reward-placeholder"
                  >
                    {rewardPlaceholder}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {(showBudgetProgressBar || isSelectionCampaign) && (
          <div css={styles.progressBarContainer}>
            {showBudgetProgressBar && (
              <CampaignBudgetProgressBar color="#ec4d74" remainingAmount={remainingAmount} totalAmount={totalAmount} />
            )}
            {isOverBudget ? (
              <div css={styles.overBudgetContainer}>
                <Icomoon color="#ff5f5f" icon="warning" size={16} />
                <div>{t('Annotation.Due to maximum participation, we have closed submissions')}</div>
              </div>
            ) : isSelectionCampaign ? (
              <SelectionMessage appliedStatus={appliedStatus} startDate={startDate} />
            ) : null}
          </div>
        )}

        {joinedMethods && !!joinedMethods.length && (
          <div css={styles.joinedMethodsContainer} className="joined-methods-container">
            <div>{t('Account you will post from')}</div>
            <div>
              {joinedMethods
                .sort((a, b) => promotionMethodValues.indexOf(a.type) - promotionMethodValues.indexOf(b.type))
                .map(({ type }) => {
                  const promotionMethod = promotionMethods.find(({ value }) => value === type);
                  const isSocialMedia = !promotionMethod?.title;
                  const promotionMethodValue = promotionMethod?.value as CampaignPromotionMethod;

                  return (
                    <div css={styles.nonPostTrackingJoinedMethod} key={type}>
                      <JoinedSocialMediaIndicator
                        color={
                          isSocialMedia ? switchSocialBackgroundColor(promotionMethodValue) : THEME.colors.gray.main
                        }
                        isGradient={[
                          CampaignPromotionMethod.INSTAGRAM,
                          CampaignPromotionMethod.INSTAGRAM_STORY,
                        ].includes(promotionMethodValue)}
                      >
                        <Icomoon
                          color={THEME.font.colors.white}
                          icon={
                            isSocialMedia
                              ? switchImage({
                                  dynamicColor: true,
                                  simplify: true,
                                  socialMedia: promotionMethodValue,
                                })
                              : promotionMethod.icon
                          }
                          size={14}
                        />
                      </JoinedSocialMediaIndicator>
                      <div>{isSocialMedia ? switchText(promotionMethodValue) : t(promotionMethod?.title)}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {joinedMethodAccounts && !!joinedMethodAccounts.length && (
          <div css={styles.joinedMethodsContainer} className="joined-method-accounts-container">
            <div>{t('Account you will post from')}</div>
            <div>
              {joinedMethodAccounts.map(
                (
                  {
                    avatar,
                    description,
                    email,
                    icon,
                    isSocialAccount,
                    name,
                    title: methodTitle,
                    type: socialMediaType,
                    url,
                  },
                  index
                ) => (
                  <div key={index}>
                    {isSocialAccount ? (
                      <div css={styles.joinedSocialAccounts} className="joined-social-accounts">
                        <div>
                          <Avatar src={avatar || ''} size={32} title={name} />
                          <JoinedSocialMediaIndicator
                            color={switchSocialBackgroundColor(socialMediaType)}
                            isAbsolute
                            isGradient={socialMediaType === SocialAccountType.INSTAGRAM}
                          >
                            <Icomoon
                              color="#fff"
                              icon={switchImage({ dynamicColor: true, simplify: true, socialMedia: socialMediaType })}
                              size={10}
                            />
                          </JoinedSocialMediaIndicator>
                        </div>
                        <div>{name}</div>
                      </div>
                    ) : (
                      <div css={styles.joinedPromotionMethods} className="joined-promotion-methods">
                        <Icomoon icon={icon || ''} size={isMobileView ? 26 : 18} />
                        <div>{methodTitle}</div>
                        <TextCutter lines={1} text={url || email || description || ''} />
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>

      <div css={styles.productInformationContainer} className="product-information-container">
        <InfoHeaderTitle>{t('Label.Product information')}</InfoHeaderTitle>
        <InfoField className="product-name">
          <InfoTitle>{t('Label.Product Name')}</InfoTitle>
          <RichEditor
            disable
            height="auto"
            setDefaultStyle={RICH_EDITOR_STYLES}
            setOptions={{ resizingBar: false }}
            hideToolbar
            value={title || ''}
          />
        </InfoField>

        <InfoField className="service-information">
          <InfoTitle>{t('Label.Product / Service Information')}</InfoTitle>
          <RichEditor
            disable
            height="auto"
            // User shouldn't be able to upload images from AnyTag rich editor
            // maybe user copy from somewhere else and paste into it
            // to avoid oversize image break the mobile view UI
            // set max-width for rich editor on mobile view to be scrollable
            setDefaultStyle={RICH_EDITOR_STYLES + (isMobileView ? MOBILE_MAX_WIDTH : '')}
            setOptions={{ resizingBar: false }}
            hideToolbar
            value={serviceInformation || ''}
          />
          {(campaignUrl || clickUrl || serviceUrl) && (
            <StyledButton
              postfixIcon={<Icomoon icon="share-arrow-top-right" size={11} />}
              target="_blank"
              text="Check website"
              to={campaignUrl || clickUrl || serviceUrl || ''}
            />
          )}
        </InfoField>

        {(clickUrl || serviceUrl) && (
          <InfoField>
            <InfoTitle>{t('Label.Product / Service URL')}</InfoTitle>
            <InfoContent>{clickUrl || serviceUrl}</InfoContent>
          </InfoField>
        )}

        {isManualApprovalApplying && (
          <InfoField>
            <InfoTitle>{t('Label.Your Next Actions')}</InfoTitle>
            <div css={styles.selectionActionContainer}>
              {SELECTION_ACTIONS.map((action, index) => (
                <div key={index}>
                  <div>{`${index + 1}.`}</div>
                  <div>{t(`Annotation.${action}`)}</div>
                </div>
              ))}
            </div>
          </InfoField>
        )}
      </div>

      <div css={styles.requestDetailsContainer}>
        <InfoHeaderTitle>{t('Label.Request Details')}</InfoHeaderTitle>
        <div css={styles.postingPeriodContainer} className="posting-period-container">
          {startDate && (
            <InfoField>
              <InfoTitle>{t('Label.Posting Period')}</InfoTitle>
              <InfoContent>{`${localizedDateFormatter(
                new Date(startDate),
                isJPLanguage ? 'yoMMMdo' : 'LLLL dd, yyyy',
                i18n.language as AppLanguage
              )} ${
                endDate
                  ? `- ${localizedDateFormatter(
                      new Date(endDate),
                      isJPLanguage ? 'yoMMMdo' : 'LLLL dd, yyyy',
                      i18n.language as AppLanguage
                    )}`
                  : ''
              }`}</InfoContent>
            </InfoField>
          )}

          <InfoField className="join-methods-container">
            {isCampaignSocialMedias ? (
              <>
                <InfoTitle>{t('Label.Join Methods')}</InfoTitle>
                <JoinMethodsContainer isMinFollower={!!minFollowers}>
                  {socialMedias.map(socialMedia => (
                    <div key={socialMedia}>
                      <Icomoon icon={switchImage({ socialMedia })} size={16} />
                      <div>
                        {minFollowers
                          ? `${t(switchText(socialMedia))}: ${t('Campaign social media required followers', {
                              count: formatNumberWithCommas(minFollowers, 0) as never,
                            })}`
                          : t(switchText(socialMedia))}
                      </div>
                    </div>
                  ))}
                </JoinMethodsContainer>
              </>
            ) : (
              <>
                <InfoTitle>{t('Label.Promotion Method Requirements')}</InfoTitle>
                <JoinMethodsContainer isMinFollower={!!minFollowers}>
                  {promotionMethods.map(({ icon, title: methodTitle, value }) => (
                    <div key={value}>
                      <Icomoon icon={icon ? icon || '' : switchImage({ socialMedia: value })} size={16} />
                      <div>
                        {methodTitle
                          ? t(methodTitle)
                          : minFollowers
                          ? `${t(switchText(value))}: ${t('Campaign social media required followers', {
                              count: formatNumberWithCommas(minFollowers, 0) as never,
                            })}`
                          : t(switchText(value))}
                      </div>
                    </div>
                  ))}
                </JoinMethodsContainer>
              </>
            )}
          </InfoField>
        </div>

        <InfoField className="post-requirements">
          <InfoTitle>{t('Label.Post Requirements')}</InfoTitle>
          <RichEditor
            disable
            height="auto"
            setDefaultStyle={RICH_EDITOR_STYLES + (isMobileView ? MOBILE_MAX_WIDTH : '')}
            setOptions={{ resizingBar: false }}
            hideToolbar
            value={requirement || ''}
          />
        </InfoField>

        {maximumRevenuePerInfluencer !== undefined && maximumRevenuePerInfluencer !== null && (
          <InfoField className="max-revenue-container">
            <InfoTitle>{t('Label.Maximum Revenue')}</InfoTitle>
            <InfoContent>{`${currencySymbol}${formatNumberWithCommas(
              maximumRevenuePerInfluencer,
              undefined,
              currency || undefined
            )}`}</InfoContent>
          </InfoField>
        )}

        {(!!materials?.length || sampleUrl) && (
          <InfoField className="materials-container">
            <InfoTitle>{t('Label.Posting Samples')}</InfoTitle>
            {sampleUrl && (
              <AnchorButton href={sampleUrl} rel="noopener noreferrer" target="_blank">
                <span>{t('Button.Check samples')}</span>
                <Icomoon icon="share-arrow-top-right" size={16} />
              </AnchorButton>
            )}
            {!!materials?.length && (
              <div css={isMobileView && { display: 'flex', justifyContent: 'center' }}>
                <CarouselV2 items={materials.map(material => material.url)} {...carouselDimensions} />
              </div>
            )}
          </InfoField>
        )}

        <div
          css={{
            display: 'grid',
            gap: THEME.box.gaps.xxl,
            position: 'relative',
            ...((isManualApprovalApplying || hidePostRequirements) && { height: 180, overflow: 'hidden' }),
          }}
        >
          {(isManualApprovalApplying || hidePostRequirements) && (
            <div css={styles.hideRequestDetails}>
              <div>
                {!isJoinableMethods ? (
                  <>
                    <div>
                      {t('Annotation.You can check more details after you connect', {
                        socialAccount: t(switchText(socialMedias[0]) || 'any promotional methods'),
                      })}
                    </div>
                    <ThemeButton
                      customPalette={mainBlack}
                      text="Connect Social Accounts"
                      onClick={onClickRedirectSocialConnect}
                    />
                  </>
                ) : (
                  <div>{t('Annotation.You can check more details after you get approved to this campaign')}</div>
                )}
              </div>
            </div>
          )}

          {isPostTracking && !!hashtags?.length && (
            <InfoField className="hashtags-container">
              <InfoTitle>{t('Label.Paste “Hashtags” in your post')}</InfoTitle>
              {isJoinedCampaign ? (
                <>
                  <div css={styles.hashtagsContainer}>
                    {hashtags.map((hashtag, hashtagIndex) => (
                      <div key={hashtagIndex}>{`#${hashtag}`}</div>
                    ))}
                  </div>
                  <StyledButton text="Copy Hashtags" onClick={() => handleCopyHasHashtag(hashtags)} />
                </>
              ) : (
                <InfoContent>{t('Annotation.Hashtags will be displayed after you joined this campaign')}</InfoContent>
              )}
            </InfoField>
          )}

          {isCouponCampaign && (
            <InfoField className="coupon-codes-container">
              <InfoTitle>{t('Label.Paste “Coupon Code” in your post')}</InfoTitle>
              {isJoinedCampaign && couponCode ? (
                <div css={styles.couponCodeContainer}>
                  <div>{couponCode}</div>
                  <StyledButton text="Copy Code" onClick={() => handleCopyHasText(couponCode)} />
                </div>
              ) : (
                <InfoContent>
                  {t('Annotation.Coupon Code will be displayed after you joined this campaign')}
                </InfoContent>
              )}
            </InfoField>
          )}

          {trackingUrl && (
            <InfoField className="tracking-url-container">
              <InfoTitle>{t('Title.Please include tracking URL in your post')}</InfoTitle>
              {isJoinedCampaign ? (
                <div css={styles.trackingUrlContainer}>
                  <div>
                    {t(
                      'Annotation.To ensure that our system can track clicks and conversions generated through your post, kindly include the tracking URL'
                    )}
                  </div>
                  <div>
                    **
                    <Trans i18nKey="Annotation.Please include tracking URL in your post">
                      text<b>text</b>
                    </Trans>
                  </div>
                  <div>{trackingUrl}</div>
                  <StyledButton text="Copy URL" onClick={() => handleCopyHasText(trackingUrl)} />
                </div>
              ) : (
                <InfoContent>{t('Annotation.URL will be displayed after you joined this campaign')}</InfoContent>
              )}
            </InfoField>
          )}

          {materialUrl && (
            <InfoField className="material-url-container">
              <InfoTitle>{t('Label.Download Material for Posting')}</InfoTitle>
              {isJoinedCampaign ? (
                <div css={styles.materialUrlContainer}>
                  <div>{t('Annotation.Click the button and download material at the link')}</div>
                  <AnchorButton download href={materialUrl} rel="noopener noreferrer" target="_blank">
                    <Icomoon icon="import" size={16} />
                    <span>{t('Button.Download')}</span>
                  </AnchorButton>
                </div>
              ) : (
                <InfoContent>
                  {t('Annotation.Download button will be displayed after you joined this campaign')}
                </InfoContent>
              )}
            </InfoField>
          )}

          {isPosting && (
            <div css={styles.postingMessage}>
              <Icomoon color="#3892e5" icon="clock-anti-clockwise" />
              <div>
                <div>{t('Post reflection time')}</div>
                <div>{t('HashTag4')}</div>
              </div>
            </div>
          )}
          {hasAddPostsButton && socialMedias.includes(CampaignSocialMediaType.TWITTER) && (
            <InfoField className="materials-container">
              <InfoTitle>{t('After you have made a post on social media')}</InfoTitle>
              <InfoContent>
                {t('Annotation.Please report that you made a post to us by clicking a button below')}
              </InfoContent>
              <StyledButton
                disabled={!confirmMadePostAvailability}
                text="I made a tweet"
                onClick={handleMadePostClick}
                loading={loading}
              />
            </InfoField>
          )}
        </div>

        {isCaptionGenerator && <CaptionGenerator marketplaceId={String(id)} />}
      </div>
    </div>
  );
};

const AnchorButton = styled.a({
  alignItems: 'center',
  border: '1px solid #dce5eb',
  borderRadius: 9,
  display: 'flex',
  gap: THEME.box.gaps.s,
  height: 48,
  justifyContent: 'center',

  '& span': {
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
  },

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    height: 40,
    width: 226,

    '& span': {
      fontSize: THEME.font.sizes.normal,
    },
  },

  '&:hover': {
    borderColor: '#c5d0da',
  },
});

const InfoContent = styled.div({
  color: THEME.font.colors.gray.main,
  fontSize: THEME.font.sizes.subHeading,
  wordBreak: 'break-word',
});

const InfoField = styled.div({
  display: 'grid',
  gap: THEME.box.gaps.m,
});

const InfoHeaderTitle = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: 22,
  fontWeight: 600,
});

const InfoTitle = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subHeading,
  fontWeight: 600,
});

const JoinMethodsContainer = styled.div<{ isMinFollower: boolean }>(({ isMinFollower }) => ({
  columnGap: 12,
  display: isMinFollower ? 'grid' : 'flex',
  flexWrap: 'wrap',
  rowGap: 8,

  '& > div': {
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.xs,

    '& > div': {
      color: '#596570',
      fontSize: THEME.font.sizes.subHeading,
    },
  },
}));

const JoinedSocialMediaIndicator = styled.div<{
  color: string;
  isAbsolute?: boolean;
  isGradient: boolean;
  size?: number;
}>(({ color, isAbsolute, isGradient, size = 24 }) => ({
  alignItems: 'center',
  border: '2px solid #fff',
  borderRadius: '50%',
  display: 'flex',
  height: size,
  justifyContent: 'center',
  width: size,
  ...(isAbsolute ? { bottom: 0, position: 'absolute', right: -16 } : {}),
  ...(isGradient ? { backgroundImage: color } : { background: color }),
}));

const StyledButton = styled(ThemeButton)({
  borderRadius: 9,
  height: 48,

  '& span': {
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
  },

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    height: 40,
    width: 226,

    '& span': {
      fontSize: THEME.font.sizes.normal,
    },
  },
});

const Tag = styled.div<{ color: string }>(({ color }) => ({
  alignItems: 'center',
  background: color,
  borderRadius: 13,
  color: THEME.font.colors.white,
  display: 'flex',
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 600,
  gap: 6,
  height: 'fit-content',
  justifyContent: 'center',
  padding: '6px 8px',
}));

const styles = {
  anyMethodsIndicator: css({
    alignItems: 'center',
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.hint,
    fontWeight: 600,
    justifyContent: 'center',
    padding: '7px 24px',
  }),
  campaignPeriodContainer: css({
    alignItems: 'center',
    color: THEME.font.colors.gray.main,
    display: 'flex',
    fontSize: THEME.font.sizes.subHeading,
    gap: THEME.box.gaps.xs,
    marginBottom: 8,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      marginBottom: 21,
    },
  }),
  container: css({
    background: THEME.colors.white,
    padding: 0,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: '0 0 23px 23px',
      padding: 24,
    },
  }),
  contentContainer: css({
    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      background: THEME.colors.white,
      borderRadius: '24px 24px 0 0',
      boxShadow: THEME.box.shadows.outer,
      padding: '24px 16px 24px 16px',
      marginTop: -16,
      position: 'relative',
      zIndex: 1,
    },
  }),
  couponCodeContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.s,

    '& > div:nth-of-type(1)': {
      border: THEME.box.borders.gray.main,
      borderRadius: 9,
      boxShadow: THEME.box.shadows.outer,
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subHeading,
      maxWidth: 648,
      overflow: 'hidden',
      padding: '15px 12px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        padding: 12,
      },
    },
  }),
  hashtagsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: THEME.box.gaps.s,

    '& > div': {
      background: '#eef3f7',
      borderRadius: 5,
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      padding: 8,
      wordBreak: 'break-word',
    },
  }),
  hideRequestDetails: css({
    alignItems: 'center',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    height: 'fill-available',
    justifyContent: 'center',
    padding: '40px 24px',
    position: 'absolute',
    top: 0,
    textAlign: 'center',
    width: 'fill-available',
    zIndex: 2,

    '& > div': {
      display: 'grid',
      gap: THEME.box.gaps.l,

      '& > div': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        justifySelf: 'center',

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          width: 327,
        },
      },

      '& > button': {
        borderRadius: 9,
        height: 56,
        justifySelf: 'center',

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          height: 40,
          width: 228,
        },
      },
    },
  }),
  imageContainer: css({
    background: THEME.colors.white,
    height: 375,
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 23,
      height: 365,
      marginBottom: 24,
      maxWidth: 648,
    },

    '& img': {
      height: 375,
      objectFit: 'contain',
      width: '100%',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        borderRadius: 23,
        height: 365,
      },
    },

    '& > .slick-arrow': {
      display: 'none !important',
    },

    '& > .slick-dots': {
      marginTop: '-64px !important',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        marginTop: '-48px !important',
      },

      '& li': {
        height: 12,
        margin: 'unset',
        width: 12,

        '& > button': {
          height: 12,
          width: 12,

          '&::before': {
            color: '#fff !important',
            fontSize: 8,
            height: 12,
            lineHeight: '8px',
            opacity: 0.5,
            width: 12,

            '&:focus': {
              opacity: 0.7,
            },
          },
        },

        '&.slick-active > button::before': {
          opacity: 1,
        },
      },
    },
  }),
  joinedMethodsContainer: css({
    border: '1px solid #eef3f7',
    borderRadius: 9,
    display: 'flex',
    marginTop: 24,
    padding: 16,
    position: 'relative',
    width: 'fill-available',

    '& > div:nth-of-type(1)': {
      background: THEME.colors.white,
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 700,
      padding: '0 8px',
      position: 'absolute',
      top: -8,
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      gap: THEME.box.gaps.m,
      width: 'fill-available',
    },
  }),
  joinedPromotionMethods: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.s,

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      minWidth: 140,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: 11,
      width: 'fill-available',

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        textAlign: 'right',
      },
    },
  }),
  joinedSocialAccounts: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.s,

    '& > div:nth-of-type(1)': {
      marginRight: 16,
      position: 'relative',
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },
  }),
  materialUrlContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.m,

    '& > div': {
      color: '#596570',
      fontSize: THEME.font.sizes.subHeading,
    },
  }),
  nonPostTrackingJoinedMethod: css({
    alignItems: 'center',
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
    gap: THEME.box.gaps.s,
  }),
  overBudgetContainer: css({
    background: '#ffecec',
    border: '1px solid #ff5f5f',
    borderRadius: 15,
    display: 'flex',
    gap: THEME.box.gaps.s,
    marginTop: 24,
    padding: 12,

    '& > div': {
      color: THEME.font.colors.error,
      fontSize: THEME.font.sizes.subordinate,
      flex: 1,
    },
  }),
  postingMessage: css({
    background: '#e7f2fc',
    border: '1px solid #3892e5',
    borderRadius: 15,
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: 12,
    width: 'fill-available',

    '& > div': {
      display: 'grid',
      gap: THEME.box.gaps.xs,

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.blue.main,
        fontSize: 13,
        fontWeight: 700,
      },

      '& > div:nth-of-type(2)': {
        color: '#5aa4ea',
        fontSize: 13,
      },
    },
  }),
  postingPeriodContainer: css({
    alignItems: 'flex-start',
    display: 'flex',
    gap: THEME.box.gaps.xxl,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      display: 'grid',
      gap: 32,
    },

    '& > div:nth-of-type(1)': {
      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        width: '40%',
      },
    },

    '& > div:nth-of-type(2)': {
      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        width: '60%',
      },
    },
  }),
  productInformationContainer: css({
    borderBottom: '1px solid #dee5ec',
    display: 'grid',
    gap: THEME.box.gaps.xxl,
    padding: '24px 0',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      gap: 32,
      padding: '40px 16px',
    },
  }),
  progressBarContainer: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderBottom: '1px solid #dee5ec',
      paddingBottom: 24,
    },
  }),
  requestDetailsContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.xxl,
    padding: '24px 0',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      gap: 32,
      padding: '40px 16px',
    },
  }),
  rewardContainer: css({
    alignItems: 'center',
    display: 'flex',
    marginBottom: 16,

    '& > div:nth-of-type(1)': {
      alignSelf: 'flex-start',
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      width: 'fill-available',
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      gap: THEME.box.gaps.s,

      '& > div': {
        alignItems: 'center',
        display: 'flex',
        gap: THEME.box.gaps.s,
        justifyContent: 'flex-end',

        '& > div:nth-of-type(1)': {
          color: THEME.font.colors.black.main,
          fontSize: THEME.font.sizes.heading,
          fontWeight: 600,
        },

        '& > div:nth-of-type(2)': {
          color: THEME.font.colors.gray.main,
          fontSize: THEME.font.sizes.normal,
          fontWeight: 600,
          width: 'max-content',
        },
      },
    },
  }),
  selectionActionContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.l,

    '& > div': {
      color: '#596570',
      display: 'flex',
      fontSize: THEME.font.sizes.subHeading,
      gap: THEME.box.gaps.s,
    },
  }),
  socialMediasContainer: css({
    display: 'flex',
    gap: THEME.box.gaps.xs,
    marginBottom: 8,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      marginBottom: 16,
    },
  }),
  socialMediaIndicator: css({
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    width: 24,
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    marginBottom: 8,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      fontSize: THEME.font.sizes.title,
      marginBottom: 12,
    },
  }),
  trackingUrlContainer: css({
    display: 'grid',

    '& > div:nth-of-type(1)': {
      color: '#596570',
      fontSize: THEME.font.sizes.subHeading,
      marginBottom: 4,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.error,
      fontSize: THEME.font.sizes.subordinate,
      marginBottom: 8,

      '& > b': {
        fontWeight: 700,
      },
    },

    '& > div:nth-of-type(3)': {
      border: THEME.box.borders.gray.main,
      borderRadius: 9,
      boxShadow: THEME.box.shadows.outer,
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subHeading,
      marginBottom: 8,
      maxWidth: '100%',
      overflow: 'hidden',
      padding: '16px 12px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        padding: 12,
      },
    },
  }),
};

export default CampaignDetails;
