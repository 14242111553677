import React from 'react';
import { css } from '@emotion/react';
import { usePageLayout } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { BackNavigatorV2 } from '@src/components/molecules';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import CreatorAccount from './CreatorAccount';
import YourAccount from './YourAccount';

const Account = () => {
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      {!isMobileView && <BackNavigatorV2 backPath={ROUTES.MY_PAGE} title="Your Account" />}

      <YourAccount />
      <CreatorAccount />
    </div>
  );
};

const styles = {
  container: css({
    display: 'grid',
    gap: 40,
    padding: '24px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      gap: THEME.box.gaps.xxl,
    },
  }),
};

export default Account;
