import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, Icon } from '@src/components/atoms';
import { ListLoading, Tooltip } from '@src/components/molecules';
import PostRejectedMessages from '@src/components/organisms/MyJobs/Marketplace/Details/PostRejectedMessages';
import YourTasks from '@src/components/organisms/MyJobs/Marketplace/Details/YourTasks';
import {
  CampaignDetails,
  CampaignDetailsStyledComponents as SC,
  DialogAddPosts,
  DialogJoinedSelection,
} from '@src/components/shared';
import { DIALOG_REQUIRED_ACTIONS } from '@src/components/shared/Campaign/helpers';
import {
  useMarketplaceDraftPostsForInfluencerQuery,
  useMarketplaceForInfluencerQuery,
  useMarketplaceJoinedAccountLazyQuery,
  useMarketplaceJoinedMethodsV2LazyQuery,
} from '@src/graphql/hooks';
import { useDimensions, usePageLayout, useScrollToPostion, useQueryHelper } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';
import {
  CampaignPostStatusForInfluencer,
  CampaignStatusForInfluencer,
  MarketplaceCampaignAppliedStatus,
  MarketplaceCampaignDraftPostStatus,
} from '@src/__generated__/globalTypes';

enum Tabs {
  CAMPAIGN_DETAILS = 'CAMPAIGN_DETAILS',
  YOUR_TASKS = 'YOUR_TASKS',
}

const Details = () => {
  const [currTab, setCurrTab] = useState<Tabs>(Tabs.YOUR_TASKS);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useDimensions(headerRef);
  const { isMobileView } = usePageLayout();
  const { navigate, params, search, t } = useQueryHelper();
  const searchParams = new URLSearchParams(search);
  const isAddPosts = !!searchParams.get(DIALOG_REQUIRED_ACTIONS.ADD_POSTS);
  const isJoinedSelection = !!searchParams.get(DIALOG_REQUIRED_ACTIONS.JOINED_SELECTION);
  const [isAddPostsDialog, setIsAddPostsDialog] = useState<boolean>(isAddPosts);
  const [isDialogFirstInit, setIsDialogFirstInit] = useState<boolean>(isAddPosts);
  const [isJoinedSelectionDialog, setIsJoinedSelectionDialog] = useState<boolean>(isJoinedSelection);
  // documentHeight for "Your Tasks" is shorter than "Campaign Details"
  // to use caption generator, use custom height so that can scroll to bottom when switch to "Campaign Details" tab
  const { scrollToBottom, scrollToTop } = useScrollToPostion(2500);
  const campaignId = Number(params.id);
  const { data, loading } = useMarketplaceForInfluencerQuery({ variables: { pk: campaignId } });
  const [getMarketplaceJoinedAccount, { data: dataJoinedSocialAccounts }] = useMarketplaceJoinedAccountLazyQuery({
    variables: { campaignId },
  });
  const [getMarketplaceJoinedMethods, { data: dataJoinedPromotionMethods }] = useMarketplaceJoinedMethodsV2LazyQuery({
    variables: { input: { campaignId: String(campaignId) } },
  });

  const campaign = data?.marketplaceForInfluencer;
  const isDisabledAddPosts =
    campaign?.isSelectionCampaign &&
    !campaign.isAutoInfluencerApproval &&
    campaign.appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING;

  // draft post submission feature
  const isDraftPostsSubmission = !isDisabledAddPosts && campaign?.requireDraftPost;
  const tabs = [
    { title: 'Your Tasks', value: Tabs.YOUR_TASKS },
    { title: 'Campaign Details', value: Tabs.CAMPAIGN_DETAILS },
  ];
  const { data: dataDraftPosts } = useMarketplaceDraftPostsForInfluencerQuery({
    skip: !isDraftPostsSubmission,
    variables: { marketplaceId: campaignId },
  });
  const marketplaceDraftPostsForInfluencer = dataDraftPosts?.marketplaceDraftPostsForInfluencer || [];
  const latestDraftPosts = marketplaceDraftPostsForInfluencer?.[marketplaceDraftPostsForInfluencer.length - 1];
  const isDraftPostApproved = latestDraftPosts?.status === MarketplaceCampaignDraftPostStatus.APPROVED;
  const isRequiredResubmitDraftPost = latestDraftPosts?.status === MarketplaceCampaignDraftPostStatus.REJECTED;
  const disabledSubmitDraftPostButton = latestDraftPosts && !isRequiredResubmitDraftPost;

  useEffect(() => {
    if (!loading && !isDraftPostsSubmission) {
      setCurrTab(Tabs.CAMPAIGN_DETAILS);
    }
  }, [loading, isDraftPostsSubmission]);

  useEffect(() => {
    if (isAddPosts || isJoinedSelection) {
      searchParams.delete(isAddPosts ? DIALOG_REQUIRED_ACTIONS.ADD_POSTS : DIALOG_REQUIRED_ACTIONS.JOINED_SELECTION);
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [isAddPosts, isJoinedSelection]);

  useEffect(() => {
    if (
      campaign &&
      !(
        campaign.isSelectionCampaign &&
        [MarketplaceCampaignAppliedStatus.APPLYING, MarketplaceCampaignAppliedStatus.REJECTED].includes(
          campaign.appliedStatus as MarketplaceCampaignAppliedStatus
        )
      )
    ) {
      if (campaign.isPostTracking) {
        getMarketplaceJoinedAccount();
      } else {
        getMarketplaceJoinedMethods();
      }
    }
  }, [campaign?.isSelectionCampaign, campaign?.appliedStatus]);

  if (loading) {
    return (
      <SC.Container>
        <ListLoading />
      </SC.Container>
    );
  }

  if (!campaignId || !campaign) {
    return <Navigate to={ROUTES.MY_JOBS} />;
  }

  const { isAutoInfluencerApproval, isPostTracking, postStatus, startDate, status } = campaign;
  const isFinishedCampaign = status === CampaignStatusForInfluencer.FINISHED;
  const isPostRejected = [
    CampaignPostStatusForInfluencer.INEFFECTIVE,
    CampaignPostStatusForInfluencer.WARNING,
  ].includes(postStatus as CampaignPostStatusForInfluencer);
  const isCampaignStarted = new Date(startDate) <= new Date();
  const isOverbudget = isAutoInfluencerApproval && status === CampaignStatusForInfluencer.SUSPENDED;
  const hasMobileActions = isMobileView;
  const marketplaceJoinedAccount = dataJoinedSocialAccounts?.marketplaceJoinedAccount;
  const marketplaceJoinedMethods = dataJoinedPromotionMethods?.marketplaceJoinedMethodsV2;
  const joinedMethodAccounts =
    isPostTracking && marketplaceJoinedAccount
      ? [{ ...marketplaceJoinedAccount, isSocialAccount: true, type: marketplaceJoinedAccount.socialAccountType }]
      : [];
  const joinedMethods = marketplaceJoinedMethods ? marketplaceJoinedMethods?.methods.map(type => ({ type })) : [];
  const reportButtonColor = isPostRejected ? '#f8b74a' : '#6e7c89';
  const reportButtonIcon = isPostRejected ? 'warning' : 'file-text-outlined';

  const onClickAddPosts = () => {
    setIsAddPostsDialog(true);
  };

  const onClickReport = () => {
    navigate(
      generatePath(isPostTracking ? ROUTES.MY_JOBS_MARKETPLACE_ID_POST_REPORT : ROUTES.MY_JOBS_MARKETPLACE_ID_REPORT, {
        id: campaignId,
      })
    );
  };

  const onClickTab = (value: Tabs, scrollToCaptionGenerator?: boolean) => {
    setCurrTab(value);
    if (scrollToCaptionGenerator) {
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    } else if (isMobileView && value !== currTab) {
      scrollToTop();
    }
  };

  const onCloseDialogAddPosts = () => {
    setIsDialogFirstInit(false);
    setIsAddPostsDialog(false);
  };

  const waitUntilCampaignStarts = !isFinishedCampaign && (isDisabledAddPosts || !isCampaignStarted);

  const actionButtons = (
    <div>
      {!(isOverbudget && isPostRejected) && !(isDraftPostsSubmission && !isDraftPostApproved) && (
        <SC.StyledButton
          prefixIcon={<Icomoon color={reportButtonColor} icon={reportButtonIcon} size={isMobileView ? 16 : 12} />}
          text="Report"
          onClick={onClickReport}
        />
      )}
      {isOverbudget ? (
        <div css={styles.overBudgetBtn}>{t('Button.This campaign reached to budget')}</div>
      ) : isDraftPostsSubmission && !isDraftPostApproved ? (
        isMobileView && !disabledSubmitDraftPostButton ? (
          <Tooltip
            help="You will be notified via email and notification tab in AnyCreator app when you get a feedback"
            placement="top"
            triggerContainerStyles={{ width: 'fill-available' }}
          >
            <SC.StyledButton
              customPalette={mainBlack}
              disabled={disabledSubmitDraftPostButton}
              text={isRequiredResubmitDraftPost ? 'Resubmit draft post' : 'Submit the draft post'}
              to={generatePath(ROUTES.MY_JOBS_MARKETPLACE_ID_SUBMIT_DRAFT_POST, { id: campaignId })}
            />
          </Tooltip>
        ) : (
          <SC.StyledButton
            customPalette={mainBlack}
            disabled={latestDraftPosts && !isRequiredResubmitDraftPost}
            text={isRequiredResubmitDraftPost ? 'Resubmit draft post' : 'Submit the draft post'}
            to={generatePath(ROUTES.MY_JOBS_MARKETPLACE_ID_SUBMIT_DRAFT_POST, { id: campaignId })}
          />
        )
      ) : waitUntilCampaignStarts ? (
        <DisabledAddPostsButton>
          <div>{t('Button.Add Posts')}</div>
          <div>{`*${t('Please wait until this campaign starts')}`}</div>
        </DisabledAddPostsButton>
      ) : (
        <SC.StyledButton customPalette={mainBlack} text="Add Posts" onClick={onClickAddPosts} />
      )}
    </div>
  );

  return (
    <SC.Container hasMobileActions={hasMobileActions} transparent={currTab === Tabs.YOUR_TASKS}>
      <DialogAddPosts
        campaign={campaign}
        isFirstInit={isDialogFirstInit}
        open={isAddPostsDialog}
        onClose={onCloseDialogAddPosts}
        onClickUseCaptionGenerator={() =>
          isDraftPostsSubmission ? onClickTab(Tabs.CAMPAIGN_DETAILS, true) : scrollToBottom()
        }
        {...(isPostTracking ? { joinedMethodAccounts } : { joinedMethods })}
      />
      <DialogJoinedSelection
        isAutoInfluencerApproval={isAutoInfluencerApproval}
        open={isJoinedSelectionDialog}
        title={campaign.title}
        onClose={() => setIsJoinedSelectionDialog(false)}
      />
      {isMobileView ? (
        isDraftPostsSubmission ? (
          <div css={styles.toolbar}>
            <Icon>
              <Link to={ROUTES.MY_JOBS}>
                <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />
              </Link>
            </Icon>
            <div>{t('Campaign Details')}</div>
            <div>{t('Label.Joined')}</div>
          </div>
        ) : (
          <SC.BackButton to={ROUTES.MY_JOBS}>
            <Icon>
              <Icomoon css={{ cursor: 'pointer', transform: 'rotate(90deg)' }} icon="arrow-down" size={13} />
            </Icon>
          </SC.BackButton>
        )
      ) : (
        <SC.Header ref={headerRef}>
          <Link to={ROUTES.MY_JOBS}>
            <Icon>
              <Icomoon css={{ cursor: 'pointer', transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
            </Icon>
          </Link>
          {isPostRejected && !isOverbudget && (
            <div css={{ width: width ? width - 590 : 500 }}>
              <PostRejectedMessages />
            </div>
          )}
          {actionButtons}
        </SC.Header>
      )}

      {isDraftPostsSubmission && (
        <div css={styles.tabsContainer}>
          {tabs.map(({ title, value }) => (
            <Tab isActive={value === currTab} key={value} onClick={() => onClickTab(value)}>
              {title}
            </Tab>
          ))}
        </div>
      )}

      <div css={{ paddingTop: isMobileView && isDraftPostsSubmission ? 42 : 0 }}>
        {currTab === Tabs.CAMPAIGN_DETAILS ? (
          <CampaignDetails
            campaign={campaign}
            hasAddPostsButton={!waitUntilCampaignStarts}
            campaignId={campaignId}
            {...(isPostTracking ? { joinedMethodAccounts } : { joinedMethods })}
          />
        ) : (
          <YourTasks draftPosts={marketplaceDraftPostsForInfluencer} />
        )}
      </div>

      {hasMobileActions && (
        <div css={styles.actionContainer}>
          {isPostRejected && !isOverbudget && <PostRejectedMessages css={{ justifyContent: 'center' }} />}
          {actionButtons}
        </div>
      )}

      <Global styles={hasMobileActions && styles.global} />
    </SC.Container>
  );
};

const DisabledAddPostsButton = styled.div({
  alignItems: 'center',
  background: '#dee5ec',
  borderRadius: 9,
  padding: '5px 15px',
  textAlign: 'center',
  width: 228,

  '& > div:nth-of-type(1)': {
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  },

  '& > div:nth-of-type(2)': {
    color: THEME.font.colors.gray.main,
    fontSize: 11,
  },

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    width: 'fill-available',
  },
});

const Tab = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  background: isActive ? '#fff' : '#27313b',
  borderRadius: isActive ? 8 : 0,
  color: isActive ? '#27313b' : '#fff',
  cursor: 'pointer',
  flex: 1,
  fontSize: 13,
  fontWeight: 500,
  padding: '8px 0',
  textAlign: 'center',
}));

const styles = {
  actionContainer: css({
    background: THEME.colors.white,
    bottom: 0,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.xxl,
    left: 0,
    padding: '16px 12px',
    position: 'fixed',
    width: 'fill-available',
    zIndex: 2,

    '& > div:last-child': {
      display: 'flex',
      gap: THEME.box.gaps.s,
    },
  }),
  global: css({
    '.contact-line-widget': {
      bottom: 96,
    },

    '[data-radix-popper-content-wrapper]': {
      zIndex: '100 !important',

      '& > div': {
        borderRadius: 20,
      },
    },
  }),
  overBudgetBtn: css({
    alignItems: 'center',
    background: '#eef3f7',
    borderRadius: 9,
    color: THEME.font.colors.gray.main,
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    height: 56,
    justifyContent: 'center',
    padding: '0 16px',
    textAlign: 'center',
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      height: 40,
      width: 'fit-content',
    },
  }),
  tabsContainer: css({
    alignItems: 'center',
    background: THEME.colors.black.main,
    border: '2px solid #27313b',
    borderRadius: 8,
    display: 'flex',
    overflow: 'hidden',
    margin: '24px 24px 0 24px',
    width: 'fill-available',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      margin: '58px 16px 0 16px',
      position: 'fixed',
      zIndex: 2,
    },
  }),
  toolbar: css({
    alignItems: 'center',
    background: THEME.colors.white,
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: '8px 16px',
    position: 'fixed',
    width: 'fill-available',
    zIndex: 2,

    '& > span': {
      cursor: 'pointer',
      flex: 1,
      justifyContent: 'flex-start',
      zIndex: 1,

      "[dir = 'rtl'] & ": {
        justifyContent: 'flex-end',
        transform: 'scaleX(-1)',
      },
    },

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      margin: 'auto',
      left: 0,
      position: 'absolute',
      right: 0,
      textAlign: 'center',
    },

    '& > div:nth-of-type(2)': {
      background: THEME.colors.success,
      borderRadius: 12,
      color: THEME.font.colors.white,
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 600,
      justifySelf: 'flex-end',
      padding: '6px 8px',
    },
  }),
};

export default Details;
