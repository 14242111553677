import React from 'react';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import Instructions from '@src/components/organisms/SignUpInstagram/Instructions';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const InstagramConnect = () => {
  const { pathname } = useLocation();
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.CONNECT);
  const failedConnect = [
    ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT,
    ROUTES.SETTINGS_SOCIAL_CONNECT_IG_UNABLE_CONNECT,
  ].includes(pathname);
  const isMyPage = [
    ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_CONNECT,
    ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT,
  ].includes(pathname);

  const onClickConnect = () => {
    connectSocialAuth(SocialAccountType.INSTAGRAM, { isNewUI: isMyPage });
  };

  return (
    <div css={styles.container}>
      <Instructions
        backPath={isMyPage ? ROUTES.MY_PAGE_SOCIAL_CONNECT : ROUTES.SETTINGS_SOCIAL_CONNECT}
        failedConnect={failedConnect}
        onClickConnect={onClickConnect}
      />
    </div>
  );
};

const styles = {
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      alignItems: 'center',
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      padding: '0 24px',
    },
  }),
};

export default InstagramConnect;
