import React from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import anyCreatorBlack from '@src/assets/img/anyCreatorBlack.png';
import { Icomoon } from '@src/components/atoms';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { menuItems } from './helpers';

export const menuWidth = 264;

const Menu = () => {
  const { matchPath, navigate, pathname, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const showMobileMenu = !!menuItems.find(item => {
    // analytics pathname contain multiple tabs
    // example: "/analytics:ig_interaction", remove "ig_interaction" to show tab navigation on analytics page
    const name = pathname === ROUTES.ANALYTICS_LINK_IN_BIO ? ROUTES.ANALYTICS : pathname.split(':')[0];

    return matchPath(item.to, name);
  });

  return isMobileView ? (
    showMobileMenu ? (
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <div css={styles.mobileMenuContainer}>
          {menuItems.map((menu, menuIndex) => {
            const { icon, title, to } = menu;
            const isActive = !!to && `/${pathname.split('/')[1]}`.includes(to);

            return (
              <MobileMenuItem disabled={!to} isActive={isActive} key={menuIndex} to={to}>
                <Icomoon color={isActive ? THEME.colors.red.ff4470 : THEME.colors.gray.a8b4bf} icon={icon} size={22} />
                <div>{t(`Title.${title}`)}</div>
              </MobileMenuItem>
            );
          })}
        </div>
      </div>
    ) : null
  ) : (
    <div css={styles.webMenuContainer}>
      <img
        css={{ cursor: 'pointer', marginBottom: 24 }}
        height="32"
        src={anyCreatorBlack}
        width="150"
        onClick={() => navigate(ROUTES.ROOT)}
      />
      <div css={{ display: 'grid', gap: THEME.box.gaps.s }}>
        {menuItems.map((menu, menuIndex) => {
          const { icon, title, to } = menu;
          const isActive = !!to && `/${pathname.split('/')[1]}`.includes(to);

          return (
            <WebMenuItem disabled={!to} isActive={isActive} key={menuIndex} to={to}>
              <Icomoon color={isActive ? THEME.colors.red.ff4470 : THEME.colors.gray.main} icon={icon} size={24} />
              <div>{t(`Title.${title}`)}</div>
            </WebMenuItem>
          );
        })}
      </div>
    </div>
  );
};

const MobileMenuItem = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  disabled: boolean;
  isActive: boolean;
}>(({ disabled, isActive }) => ({
  display: 'grid',
  gap: 2,
  justifyContent: 'center',
  textAlign: 'center',
  width: 'fill-available',
  ...(disabled && { pointerEvents: 'none' }),

  '& > svg': {
    justifySelf: 'center',
  },

  '& > div': {
    color: isActive ? THEME.colors.red.ff4470 : THEME.colors.gray.a8b4bf,
    fontSize: THEME.font.sizes.hint,
    fontWeight: isActive ? 600 : 400,
  },
}));

const WebMenuItem = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  disabled: boolean;
  isActive: boolean;
}>(({ disabled, isActive }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  gap: THEME.box.gaps.s,
  padding: '8px 16px',
  ...(disabled && { pointerEvents: 'none' }),
  ...(isActive && { backgroundColor: THEME.colors.red.ffeef2, borderRadius: THEME.box.borderRadius.m }),

  '& > div': {
    color: isActive ? THEME.colors.red.ff4470 : THEME.colors.gray.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  },
}));

const styles = {
  mobileMenuContainer: css({
    background: THEME.colors.white,
    bottom: 0,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    padding: '8px 0 20px',
    position: 'fixed',
    width: 'fill-available',
    zIndex: 5,
  }),
  webMenuContainer: css({
    background: '#f7f8fa',
    height: '100vh',
    left: 0,
    padding: '40px 16px',
    position: 'fixed',
    top: 0,
    width: `${menuWidth - 32}px`,

    "[dir='rtl'] &": {
      left: 'unset',
      right: 0,
    },
  }),
};

export default Menu;
