import React from 'react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';

interface CircularProgressProps {
  className?: string;
  color?: string;
  size?: string;
  thickness?: string;
}

const CircularProgress = ({
  className,
  color = THEME.colors.white,
  size = '64px',
  thickness = '4px',
}: CircularProgressProps) => <Spinner className={className} color={color} size={size} thickness={thickness} />;

const Spinner = styled.span<CircularProgressProps>(({ color, size, thickness }) => ({
  animation: 'rotation 1s linear infinite',
  border: `${thickness} solid ${color}`,
  borderBottomColor: 'transparent',
  borderRadius: '50%',
  boxSizing: 'border-box',
  display: 'inline-block',
  height: size,
  width: size,

  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

export default CircularProgress;
