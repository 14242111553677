import React from 'react';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { BackNavigator } from '@src/components/molecules';
import FacebookInstruction from '@src/components/organisms/SignUpFacebook/FacebookInstruction';
import { ROUTES } from '@src/shared/routes';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth, usePageLayout } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const FacebookGrantPermission = () => {
  const { isMobileView } = usePageLayout();
  const { pathname } = useLocation();
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.CONNECT);
  const isMyPage = pathname === ROUTES.MY_PAGE_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION;

  const onClickEditSettings = () => {
    connectSocialAuth(SocialAccountType.FACEBOOK, { isNewUI: isMyPage });
  };

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <BackNavigator
          css={{ marginBottom: 24 }}
          title="Grant permission"
          to={isMyPage ? ROUTES.MY_PAGE_SOCIAL_CONNECT : ROUTES.SETTINGS_SOCIAL_CONNECT}
        />
      )}

      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <div css={{ width: 750 }}>
          <FacebookInstruction onClickEditSettings={onClickEditSettings} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    margin: '16px 24px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      margin: 16,
    },
  }),
};

export default FacebookGrantPermission;
