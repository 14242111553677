import React from 'react';
import { css } from '@emotion/react';
import { BackNavigator } from '@src/components/molecules';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth, useQueryHelper, usePageLayout } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import Instruction from './Instruction';

const TiktokGrantPermission = () => {
  const { pathname, search } = useQueryHelper();
  const query = new URLSearchParams(search);
  const socialAccountId = query.get('id');
  const { isMobileView } = usePageLayout();
  const isMyPage = pathname === ROUTES.MY_PAGE_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION;
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.CONNECT);
  const { connectSocialAuth: tiktokReconnectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.TIKTOK_RECONNECT);

  const onClickConnect = () => {
    connectSocialAuth(SocialAccountType.TIKTOK);
  };

  const onClickReconnect = () => {
    if (socialAccountId) {
      tiktokReconnectSocialAuth(SocialAccountType.TIKTOK, {
        isNewUI: isMyPage,
        socialAccountId: Number(socialAccountId),
      });
    }
  };

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <BackNavigator
          css={{ marginBottom: 24 }}
          title="Grant permission"
          to={isMyPage ? ROUTES.MY_PAGE_SOCIAL_CONNECT : ROUTES.SETTINGS_SOCIAL_CONNECT}
        />
      )}

      <Instruction isConnect={!socialAccountId} onClickConnect={onClickConnect} onClickReconnect={onClickReconnect} />
    </div>
  );
};

const styles = {
  container: css({
    margin: '16px 24px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      margin: 16,
    },
  }),
};

export default TiktokGrantPermission;
