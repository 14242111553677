import React from 'react';
import { css } from '@emotion/react';
import anyCreatorBlack from '@src/assets/img/anyCreatorBlack.png';
import { Icomoon, Icon } from '@src/components/atoms';
import Badge from '@src/components/organisms/Notification/Badge';
import { dynamicTitleState, toolbarBackState, useRecoil } from '@src/recoilAtoms';
import { useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';

export const DYNAMIC_TITLE = '_dynamic';

export interface ToolbarProps {
  back?: boolean | string;
  title?: string;
}

const Toolbar = ({ back, title }: ToolbarProps) => {
  const {
    recoilState: { title: dynamicTitle, interpolation },
  } = useRecoil(dynamicTitleState);
  const {
    recoilState: { backMethod },
  } = useRecoil(toolbarBackState);
  const { params, search, navigate, t } = useQueryHelper();
  const isDynamicTitle = title === DYNAMIC_TITLE; // if need to pass another _dynamic title - we can make recoil atom for this
  const updatedTitle = isDynamicTitle ? t(dynamicTitle, { ...interpolation }) : t(`Title.${title}`);
  const redirectPath = typeof back === 'string' ? generatePath(back, params as any) + search : '';

  return (
    <div css={styles.container}>
      <div css={{ alignItems: 'center', display: 'flex', width: 'fill-available' }}>
        {title ? (
          <>
            {back && (
              <Icon>
                <Icomoon
                  css={{ cursor: 'pointer', transform: 'rotate(90deg)' }}
                  icon="arrow-down"
                  size={16}
                  onClick={() => (backMethod ? backMethod() : redirectPath ? navigate(redirectPath) : navigate(-1))}
                />
              </Icon>
            )}
            <div css={styles.title}>{updatedTitle}</div>
          </>
        ) : (
          <>
            <div css={{ width: 'fill-available' }}>
              <img src={anyCreatorBlack} width="140" onClick={() => navigate(ROUTES.ROOT)} />
            </div>
            <Badge />
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    display: 'flex',
    height: 40,
    padding: '0 16px',
    position: 'fixed',
    top: 0,
    width: 'fill-available',
    zIndex: 5,
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    textAlign: 'center',
    width: 'fill-available',
  }),
};

export default Toolbar;
