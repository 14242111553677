import React, { useEffect, ReactNode } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGlobalLayout, usePageLayout } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import IntercomInitializer from '../Intercom';
import LineWidget from '../LineWidget';
import { menuItems } from './helpers';
import Menu, { menuWidth } from './Menu';
import Toolbar, { ToolbarProps, DYNAMIC_TITLE } from './Toolbar';

export { DYNAMIC_TITLE };

export interface LayoutProps {
  backgroundColor?: string;
  hideToolbar?: boolean;
  toolbarProps?: ToolbarProps;
}

interface MainProps extends LayoutProps {
  children: ReactNode;
}

const Layout = ({ backgroundColor = '#f7f8fa', children, hideToolbar, toolbarProps }: MainProps) => {
  const { pathname } = useLocation();
  const { setGlobalLayout } = useGlobalLayout();
  const { isMobileView } = usePageLayout();
  const showMobileMenu =
    isMobileView &&
    !!menuItems.find(item => {
      // analytics pathname contain multiple tabs
      // example: "/analytics:ig_interaction", remove "ig_interaction" to show tab navigation on analytics page
      const name = pathname === ROUTES.ANALYTICS_LINK_IN_BIO ? ROUTES.ANALYTICS : pathname.split(':')[0];

      return matchPath({ path: item.to }, name);
    });
  const showToolbar = isMobileView && !hideToolbar;

  useEffect(() => {
    setGlobalLayout({ hasMobileMenu: showMobileMenu });
  }, [showMobileMenu]);

  return (
    <div>
      {showToolbar && <Toolbar {...toolbarProps} />}
      <Menu />
      <Content backgroundColor={backgroundColor} marginTop={showToolbar} paddingBottom={showMobileMenu}>
        {children}
      </Content>
      <LineWidget />
      <IntercomInitializer />
    </div>
  );
};

const Content = styled.div<{
  backgroundColor: string;
  marginTop: boolean;
  paddingBottom: boolean;
}>(({ backgroundColor, marginTop, paddingBottom }) => ({
  backgroundColor,
  width: '100%',
  ...(marginTop && { marginTop: 40 }),
  ...(paddingBottom && { paddingBottom: 88 }),

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    marginLeft: `${menuWidth}px`,
    width: `calc(100% - ${menuWidth}px)`,

    "[dir='rtl'] &": {
      marginLeft: 'unset',
      marginRight: `${menuWidth}px`,
    },
  },
}));

export default Layout;
